import React from "react";

import fiveStarRatingImage from "./images/fhrs-5-rating.jpg";
import fourStarRatingImage from "./images/fhrs-4-rating.jpg";
import threeStarRatingImage from "./images/fhrs-3-rating.jpg";
import twoStarRatingImage from "./images/fhrs-2-rating.jpg";
import oneStarRatingImage from "./images/fhrs-1-rating.jpg";
import zeroStarRatingImage from "./images/fhrs-0-rating.jpg";
import awaitingInspectionRatingImage from "./images/fhrs-awaiting-inspection.jpg";
import awaitingPublicationRatingImage from "./images/fhrs-awaiting-publication.jpg";
import exemptRatingImage from "./images/fhrs-exempt.jpg";
import passImage from "./images/fhis-pass.jpg";
import passEatSafeImage from "./images/fhis-pass-and-eat-safe.jpg";
import improvementRequiredImage from "./images/fhis-improvement-required.jpg";
import fhisExemptRatingImage from "./images/fhis-exempt.jpg";

import fullSizeFiveStarRatingImage from "./images/large/fhrs-5-rating-large.jpg";
import fullSizeFourStarRatingImage from "./images/large/fhrs-4-rating-large.jpg";
import fullSizeThreeStarRatingImage from "./images/large/fhrs-3-rating-large.jpg";
import fullSizeTwoStarRatingImage from "./images/large/fhrs-2-rating-large.jpg";
import fullSizeOneStarRatingImage from "./images/large/fhrs-1-rating-large.jpg";
import fullSizeZeroStarRatingImage from "./images/large/fhrs-0-rating-large.jpg";
import fullSizeAwaitingInspectionRatingImage from "./images/large/fhrs-awaiting-inspection-large.jpg";
import fullSizeAwaitingPublicationRatingImage from "./images/large/fhrs-awaiting-publication-large.jpg";
import fullSizeExemptRatingImage from "./images/large/fhrs-exempt-large.jpg";
import fullSizePassImage from "./images/large/fhis-pass-large.jpg";
import fullSizePassEatSafeImage from "./images/large/fhis-pass-and-eat-safe-large.jpg";
import fullSizeImprovementRequiredImage from "./images/large/fhis-improvement-required-large.jpg";
import fullSizeFhisExemptRatingImage from "./images/large/fhis-exempt-large.jpg";

interface Props {
	rating: string;
	fullSize: boolean;
}

const RatingImage: React.FC<Props> = ({ rating, fullSize }) => {
	let ratingImage: string | undefined;

	switch (rating) {
		case "5": {
			ratingImage = fullSize
				? fullSizeFiveStarRatingImage
				: fiveStarRatingImage;
			break;
		}
		case "4": {
			ratingImage = fullSize
				? fullSizeFourStarRatingImage
				: fourStarRatingImage;
			break;
		}
		case "3": {
			ratingImage = fullSize
				? fullSizeThreeStarRatingImage
				: threeStarRatingImage;
			break;
		}
		case "2": {
			ratingImage = fullSize ? fullSizeTwoStarRatingImage : twoStarRatingImage;
			break;
		}
		case "1": {
			ratingImage = fullSize ? fullSizeOneStarRatingImage : oneStarRatingImage;
			break;
		}
		case "0": {
			ratingImage = fullSize
				? fullSizeZeroStarRatingImage
				: zeroStarRatingImage;
			break;
		}
		case "AwaitingInspection": {
			ratingImage = fullSize
				? fullSizeAwaitingInspectionRatingImage
				: awaitingInspectionRatingImage;
			break;
		}
		case "Awaiting Inspection": {
			ratingImage = fullSize
				? fullSizeAwaitingInspectionRatingImage
				: awaitingInspectionRatingImage;
			break;
		}
		case "AwaitingPublication": {
			ratingImage = fullSize
				? fullSizeAwaitingPublicationRatingImage
				: awaitingPublicationRatingImage;
			break;
		}
		case "Awaiting Publication": {
			ratingImage = fullSize
				? fullSizeAwaitingPublicationRatingImage
				: awaitingPublicationRatingImage;
			break;
		}
		case "Exempt": {
			ratingImage = fullSize ? fullSizeExemptRatingImage : exemptRatingImage;
			break;
		}
		case "Pass": {
			ratingImage = fullSize ? fullSizePassImage : passImage;
			break;
		}
		case "Pass and Eat Safe": {
			ratingImage = fullSize ? fullSizePassEatSafeImage : passEatSafeImage;
			break;
		}
		case "Improvement Required": {
			ratingImage = fullSize
				? fullSizeImprovementRequiredImage
				: improvementRequiredImage;
			break;
		}
		case "FHIS Exempt": {
			ratingImage = fullSize
				? fullSizeFhisExemptRatingImage
				: fhisExemptRatingImage;
			break;
		}
		default: {
			ratingImage = undefined;
			break;
		}
	}

	return (
		<img
			src={ratingImage}
			alt={`${rating} star rating`}
			className="rating-image img-fluid"
		/>
	);
};

export default RatingImage;
