import React from "react";
import RatingImage from "../rating-image/rating-image.component";
import { EstablishmentDetail } from "../types/types";
import "./rating-detail.component.scss";
import EstablishmentRating from "./establishment-rating.component";

interface Props {
	establishment: EstablishmentDetail;
}

const RatingDetail: React.FC<Props> = ({ establishment }) => {
	return (
		<>
			<RatingImage rating={establishment.ratingValue} fullSize={true} />
			<EstablishmentRating establishment={establishment} />
		</>
	);
};

export default RatingDetail;
