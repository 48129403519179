import React from "react";
import { Link } from "react-router-dom";
import BusinessCard from "../business-card/business-card.component";
import Button from "../button/button-component";
import Header from "../header/header.component";
import Loading from "../loading/loading-component";
import { Establishment } from "../types/types";

interface Props {
	isLoadingMore: boolean;
	establishments: Establishment[];
	nextCursor: string;
	heading?: string;
	strapline?: string;
	handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SearchResults: React.FC<Props> = ({
	establishments,
	nextCursor,
	heading,
	strapline,
	isLoadingMore,
	handleClick,
}) => {
	return (
		<>
			{heading ? <Header heading={heading} strapline={strapline} /> : null}

			<div className="search-results">
				{establishments.map((est) => (
					<Link key={est.id} to={getUrl(est.slug, est.fhrsId)}>
						<BusinessCard establishment={est} />
					</Link>
				))}

				{establishments &&
					nextCursor !== "" &&
					(isLoadingMore ? (
						<Loading fullscreen={false} />
					) : (
						<Button handleClick={handleClick} text={"Load more..."}></Button>
					))}
			</div>
		</>
	);
};

const getUrl = (
	establishmentSlug: string,
	establishmentFhrsId: string
): string => {
	return `/${establishmentSlug}/${establishmentFhrsId}`;
};

export default SearchResults;
