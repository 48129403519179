import React from "react";
import { Link } from "react-router-dom";

const BusinessOwners: React.FC = () => {
	return (
		<div className="establishment-detail-section">
			<h2>Are you the business owner or manager?</h2>

			<p>
				If you have noticed any inaccuracies or privacy issues on this page, we
				kindly request that you email us at{" "}
				<a href="mailto:foodowlapp@gmail.com">foodowlapp@gmail.com</a> as soon
				as possible so that we can address the issue and make any necessary
				corrections or changes.
			</p>
			<p>
				Please note that we rely on information provided by the UK Food
				Standards Agency API and while we strive to ensure the accuracy of the
				information on our page, we cannot guarantee the accuracy of the
				information provided by the Food Standards Agency.
			</p>

			<p>
				Please also see our <Link to={"/terms-of-use"}>terms of use</Link> and{" "}
				<Link to={"/privacy-policy"}>privacy policy</Link> for more information.
			</p>
		</div>
	);
};

export default BusinessOwners;
