import { gql } from "@apollo/react-hooks";

export const GET_ESTABLISHMENTS_QUERY = gql`
	query GetEstablishments($businessName: String!, $location: String!, $cursor: String, $limit: Int!) {
		establishments(input: {businessName: $businessName, location: $location, cursor: $cursor, limit: $limit}) {
			establishments {
				id
				fhrsId
				businessName
				addressLine1
				addressLine2
				addressLine3
				addressLine4
				postcode
				ratingValue
				totalScore
				slug
				schemeType
			}
			nextCursor
			size
		}
	}
`;