import React from "react";

import "./loading.component.scss";

interface Props {
	fullscreen: boolean;
}

const Loading: React.FC<Props> = ({ fullscreen }) => {
	return (
		<div className={fullscreen ? `loader loader-fullscreen` : `loader mt-4`}>
			<div role="status" className="spinner-border">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
};

export default Loading;
