import React from "react";
import { Link } from "react-router-dom";
import "./button.scss";

interface Props {
	text: string;
	link: string;
	styles?: string;
}

const ButtonLink: React.FC<Props> = ({ text, link, styles }) => {
	return (
		<Link className={`btn ${styles}`} to={link}>
			{text}
		</Link>
	);
};

export default ButtonLink;
