import React from "react";

interface Props {
	heading: string;
	strapline?: string;
}

const Header: React.FC<Props> = ({ heading, strapline }) => {
	return <h2>{heading}</h2>;
};

export default Header;
