import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import RatingImage from "../rating-image/rating-image.component";
import { Establishment } from "../types/types";
import { getAddress } from "../util/data.utils";

import "./business-card.component.scss";

interface Props {
	establishment: Establishment;
}

const BusinessCard: React.FC<Props> = ({ establishment }) => {
	return (
		<div className="card text-center">
			<h1>{establishment.businessName}</h1>
			<p>{getAddress(establishment)}</p>

			<RatingImage rating={establishment.ratingValue} fullSize={false} />

			{establishment.schemeType.toLowerCase() === "fhrs" ? (
				<>
					<p className="total-score">
						Total Score: {establishment.totalScore} (lower is better)
					</p>

					{establishment.totalScore === "0" ? (
						<p className="perfect-score">
							<FontAwesomeIcon
								icon={faStar}
								className={"perfect-score__icon perfect-score__icon--left"}
							/>
							PERFECT HYGIENE RATING SCORE
							<FontAwesomeIcon
								icon={faStar}
								className={"perfect-score__icon perfect-score__icon--right"}
							/>
						</p>
					) : null}
				</>
			) : null}
		</div>
	);
};

export default BusinessCard;
