import React from "react";
import { Link } from "react-router-dom";
import AdUnit from "../../components/ads/ad-unit.component";
import Head from "../../components/head/head.component";

const FoodHygieneTipsPage: React.FC = () => {
	const title = "Boost Your Hygiene Rating With These Proven Food Hygiene Tips";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					A good food hygiene rating is essential for any food business.&nbsp;
					It shows customers that you take food safety seriously.
				</p>
				<p>
					In fact, some people will avoid eating at restaurants and takeaways
					that don't have a 5-star rating.
				</p>

				<AdUnit slotId={"9543246006"} />

				<p>
					So, to help you on your way to achieving a 5-star rating, I've
					compiled a bunch of food hygiene tips.&nbsp;
				</p>
				<p>
					These tips come from real people who have undergone food hygiene
					inspections in the UK.
				</p>
				<p>Let's get to it!</p>
				<ul>
					<li>
						<strong>Reduce the risk of cross-contamination by using</strong>{" "}
						<strong>multiple</strong> <strong>sinks.&nbsp;</strong> The Food
						Standards Agency requires a minimum of two sinks." One sink for
						hand-washing, the other for food washing/washing up.&nbsp; If you
						only have two sinks, you need to first clean down your washing up
						sink. Then you can wash the fruit/veg etc.&nbsp; You must then clean
						down the sink again.&nbsp; Always prepare any ready-to-eat foods
						first.&nbsp; Do this before moving onto washing raw food.&nbsp; This
						is something a food hygiene inspector may ask you about if you only
						have two sinks.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Make sure to have a measuring cup</strong> for soap and
						detergents.&nbsp; This enables you to consistently put the correct
						amount of soap/detergent in a bucket or sink.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Prevent contamination</strong> by keeping them cleaning
						products well away from food and ingredients.
					</li>
				</ul>

				<ul>
					<li>
						<strong>Create a cleaning schedule</strong> and write down how you
						prepare the kitchen when you start.&nbsp; You should also
						document&nbsp; how you clean it when you're finished.
					</li>
				</ul>

				<AdUnit slotId={"8230164335"} />

				<ul>
					<li>
						<strong>Clean everything.</strong>&nbsp; This includes (but not
						limited to) the oven, microwave and descaling kettles.&nbsp; You
						should also wipe down doors, door handles, sweep the floor and make
						sure bins are clean.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Empty waste bins</strong> as much as possible.&nbsp; Avoid
						letting them overflow.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Have daily cleaning checklists</strong> and deep clean
						(weekly) checklists.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Have a good cleaning rotation</strong> of all your cleaning
						cloths.
					</li>
				</ul>

				<AdUnit slotId={"3304290658"} />

				<ul>
					<li>
						<strong>Have paper towels/blue roll next to the sink</strong> so
						you're not using the same tea towels over and over again.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Label all ingredients</strong>, especially things that
						contain nuts.&nbsp; Label with open date and use by date.&nbsp; Keep
						people accountable by asking them to write their name on the
						label.&nbsp; Remove old labels before applying new ones.&nbsp; Also,
						get some 'use first' labels so staff know which products to use
						first.&nbsp; This ensures your oldest stock doesn't get wasted.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Keep a rotation sheet</strong> if keeping ingredients out of
						their original packaging.&nbsp; This helps ensure that food and
						ingredients with earlier use by dates get consumed first.&nbsp; Not
						only does this promote food safety but it also minimises waste.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Keep records of waste</strong>.&nbsp; I.e. what waste went
						to the tip, and when.
					</li>
				</ul>

				<AdUnit slotId={"6036064075"} />

				<ul>
					<li>
						<strong>Check fridge temperatures</strong> and write them down
						morning and night.&nbsp; Fridge temperatures should be between 5 and
						8 degrees celsius.&nbsp; Ideally, it should be closer to 5 degrees
						celsius.&nbsp; If they're wrong, you will have to throw food away as
						it may have perished.&nbsp; It's possible for the electricity to
						have gone off in the night.&nbsp; As such, it is possible that the
						fridge kept food at incorrect temperatures.&nbsp; This can affect
						food such as cream and other perishable items.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Have a thermometer</strong> in the fridge and freezer.&nbsp;
						This should be alongside any temperature gauges on the outside of
						the fridge.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Check sell by dates</strong> on food in the fridge
						especially for things like butter, eggs and milk.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Check fresh items every day.</strong>
					</li>
				</ul>

				<AdUnit slotId={"7691108288"} />

				<ul>
					<li>
						<strong>Check your fridge</strong> even if you're not using it to
						check it isn't contaminated.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Clean the inside of your fridge</strong> walls, ceiling and
						floor.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Have separate shelves in your fridge</strong> for stuff that
						contain nuts and other allergens.&nbsp; Separate shelves are also
						essential if you work from your home and only have one fridge.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Check food temperatures</strong> using a sanitised probe.
					</li>
				</ul>

				<AdUnit slotId={"1991208988"} />

				<ul>
					<li>
						<strong>Clean surfaces before you use them</strong> in case someone
						else has used it.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Wash dishcloths every day</strong>.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Always check delivery van fridge temperatures</strong>
						.&nbsp; Write these temperatures down.&nbsp; Don't take delivery of
						food that isn't at the correct temperature.&nbsp; Also write down
						who the courier was and when you receive the stock.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Store ingredients</strong> <strong>correctly</strong>.&nbsp;
						Ensure that you keep lids closed at all times.
					</li>
				</ul>

				<AdUnit slotId={"5064944948"} />

				<ul>
					<li>
						<strong>Check the quality of your hand wash soap.</strong>&nbsp;
						Food Inspectors may look at your handwash to see if it foams
						up.&nbsp; Also inspect the label to see if it kills bacteria.
					</li>
				</ul>
				<ul>
					<li>
						<strong>
							Use cleaning products with BS EN 1276 or BS EN 13697 markings on
							them
						</strong>
						.&nbsp; This means using British Standard products rather than
						products produced to European standards.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Keep raw food food away from read-to-eat food.</strong>
						&nbsp; This includes uncooked food and not just raw meat and fish.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Never store food on the floor.</strong>&nbsp; Use racks or
						storage cupboards.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Always wear protective clothing</strong> and head
						coverings.&nbsp; Avoid jewellery and watches and instruct any staff
						you may have to do the same.
					</li>
				</ul>

				<AdUnit slotId={"9678127316"} />

				<h2>
					<strong>Before you go…</strong>
				</h2>
				<p>
					If you haven't already read my article on “How to Earn a 5-Star Food
					Hygiene Rating”, I highly recommend reading it.
				</p>
				<p>
					The article is very detailed.&nbsp; But if you follow the advice
					written in it, it will go a long way towards helping you achieve a
					5-star hygiene rating.
				</p>
				<p>
					<Link to="/blog/how-to-five-star-rating">
						Boost Your Business: How to Earn a 5-Star Food Hygiene Rating
					</Link>
				</p>

				<AdUnit slotId={"9783737392"} />
			</div>
		</div>
	);
};

export default FoodHygieneTipsPage;
