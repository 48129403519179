import React from "react";
import { EstablishmentDetail } from "../types/types";
import "./inspection-date.component.scss";

interface Props {
	establishment: EstablishmentDetail;
}

const InspectionDate: React.FC<Props> = ({ establishment }) => {
	const inspectionDate = (
		<div className="inspection-date">
			<div className="inspection-date__heading">Inspection Date:</div>
			<div className="inspection-date__line1">{establishment.ratingDate}</div>
		</div>
	);

	return inspectionDate;
};

export default InspectionDate;
