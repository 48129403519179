import { useQuery } from "@apollo/client";
import React from "react";
import { Link, match } from "react-router-dom";
import { AUTHORITY_LIST_QUERY } from "../../gql/authority.query";
import { BUSINESS_TYPE_LIST_QUERY as BUSINESS_TYPES_QUERY } from "../../gql/business-type.query";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";

import {
	Authority,
	AuthorityByRegionSlugInput,
	AuthorityData,
	BusinessTypeData,
} from "../../components/types/types";
import { getAuthorityBySlug } from "../../components/util/data.utils";
import Head from "../../components/head/head.component";

interface Params {
	regionSlug: string;
	authoritySlug: string;
}

interface Props {
	match?: match<Params>;
}

const BusinessTypesPage: React.FC<Props> = ({ match }) => {
	const searchParams: Params = match
		? match.params
		: { regionSlug: "", authoritySlug: "" };

	const authorityQuery = useQuery<AuthorityData, AuthorityByRegionSlugInput>(
		AUTHORITY_LIST_QUERY,
		{
			variables: {
				regionSlug: searchParams.regionSlug,
				limit: 1000000000,
			},
		}
	);

	const businessTypesQuery = useQuery<BusinessTypeData>(BUSINESS_TYPES_QUERY);

	const errors = authorityQuery.error || businessTypesQuery.error;
	const loading = authorityQuery.loading || businessTypesQuery.loading;

	const authorityData = authorityQuery.data;
	const businessTypesData = businessTypesQuery.data;

	if (loading) {
		return <Loading fullscreen={false} />;
	}

	if (!authorityData || !businessTypesData || errors) return <NoData />;

	const authority: Authority | undefined = getAuthorityBySlug(
		searchParams.authoritySlug,
		authorityData.authorityList.authorities
	);

	const title = `Business Types in ${authority?.name}`;

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					Here's a list of business types applicable to food-based businesses
					within <span className="text-bold">{authority?.name}</span>.
				</p>

				<p>
					Food-based businesses include Restaurants, Pubs, Cafés, Takeaways and
					Supermarkets. They also include other places where consumers eat such
					as schools, hospitals and food shops etc.
				</p>
				<p>
					Click on a business type below to reveal the food hygiene rating or
					inspection result given to each business within that category:
				</p>

				<div className="grid my-4">
					{businessTypesData.businessTypes.map((businessType) => (
						<Link
							key={businessType.id}
							to={getUrl(
								searchParams.regionSlug,
								searchParams.authoritySlug,
								businessType.slug
							)}
							className="article-card text-center"
						>
							{businessType.businessTypeName}
						</Link>
					))}
				</div>
			</div>
		</div>
	);
};

const getUrl = (
	regionSlug: string,
	authoritySlug: string,
	businessTypeSlug: string
): string => {
	return `/establishments/${regionSlug}/${authoritySlug}/${businessTypeSlug}`;
};

export default BusinessTypesPage;
