import { useQuery } from "@apollo/client";
import React from "react";
import { Link, match } from "react-router-dom";
import AdUnit from "../../components/ads/ad-unit.component";
import Head from "../../components/head/head.component";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import {
	Authority,
	EstablishmentDetail,
	EstablishmentByAuthorityBusinessTypeInput,
	EstablishmentsData,
} from "../../components/types/types";
import { getAddress } from "../../components/util/data.utils";
import { GET_ESTABLISHMENTS_BY_BUSINESS_TYPE_QUERY } from "../../gql/establishments-by-business-type.query";

interface Params {
	authoritySlug: string;
}

interface Props {
	match?: match<Params>;
}

const CleanestTakeawaysSandwichShopsPage: React.FC<Props> = ({ match }) => {
	const searchParams: Params = match ? match.params : { authoritySlug: "" };

	const { data, error, loading } = useQuery<
		EstablishmentsData,
		EstablishmentByAuthorityBusinessTypeInput
	>(GET_ESTABLISHMENTS_BY_BUSINESS_TYPE_QUERY, {
		variables: {
			authoritySlug: searchParams.authoritySlug,
			businessTypeSlug: "takeaway-sandwich-shop",
			rating: "5",
			limit: 1000000000,
		},
		fetchPolicy: "no-cache",
		nextFetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
	});

	if (loading) return <Loading fullscreen={true} />;

	if (error || !data) return <NoData />;

	if (data?.establishments.size === 0) {
		return (
			<div className="container panel">
				<div className="article">
					<p>
						There are currently no 'five-star' rated establishments in this
						area.
					</p>
				</div>
			</div>
		);
	}

	const authority: Authority = data.establishments.establishments[0].authority;

	const title = `Cleanest Takeaways and Sandwich Shops in ${authority.name}`;

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					There are a total of{" "}
					<span className="text-bold">{data?.establishments.size} </span>
					takeaways and sandwich shops that have been given the coveted
					'five-star' rating by the{" "}
					<span className="text-bold">{authority.name} </span>local authority.
				</p>

				<p>
					An establishment that has been given a 'five-star' rating have 'very
					good' hygiene standards. It also means that they fully comply with the
					law.
				</p>

				<AdUnit slotId={"7950128587"} />

				<p>
					To get the top rating, the establishment must perform well in three
					areas. These are:
				</p>

				<ul>
					<li>How hygienically the food is handled</li>
					<li>The physical condition of the business</li>
					<li>How the business manages ways of keeping food safe</li>
				</ul>

				<p>
					A score is given to an establishment for each of these areas and the
					scores are then added together to form an overall score.
				</p>

				<AdUnit slotId={"1865718926"} />

				<p>
					The lower the score the better the establishment has performed at the
					time of inspection.
				</p>

				<p>
					If the establishment has scored between 0 and 15, it will achieve a
					'five-star' rating.
				</p>

				<p>
					A score of zero is the best possible score that an establishment can
					achieve.
				</p>

				<p>
					So it's worth keeping an eye out for those establishments in the list
					below.
				</p>

				<p>
					You can find out{" "}
					<Link to={"/blog/ratings-explained"}>
						how food hygiene ratings are calculated here.
					</Link>
				</p>

				<p>
					Although an establishment can score well in terms of food hygiene,
					it's only a snapshot in time. But it is the responsibility of the
					business to comply with food hygiene law at all times.{" "}
				</p>

				<AdUnit slotId={"4643887513"} />

				<p>It is not a reflection of performance in areas such as:</p>

				<ul>
					<li>Quality of the food </li>
					<li>Customer service </li>
					<li>Culinary skill </li>
					<li>Presentation </li>
					<li>Comfort</li>
				</ul>

				<p>So bear that in mind when considering places to eat.</p>

				<p>
					Here's a full list of takeaways and sandwich shops in the{" "}
					{authority.name} borough that achieved a five star rating:
				</p>

				<ul>
					{data.establishments.establishments.map((est) => (
						<li>
							<Link key={est.id} to={getUrl(est.slug, est.fhrsId)}>
								{getUrlAnchorText(est)}
							</Link>{" "}
							- Total Score: {est.totalScore}
							{est.totalScore === "0" ? (
								<span className="text-bold"> (Perfect Hygiene Score)</span>
							) : null}
						</li>
					))}
				</ul>

				<AdUnit slotId={"6926473916"} />
			</div>
		</div>
	);
};

export default CleanestTakeawaysSandwichShopsPage;

const getUrl = (
	establishmentSlug: string,
	establishmentFhrsId: string
): string => {
	return `/${establishmentSlug}/${establishmentFhrsId}`;
};

const getUrlAnchorText = (est: EstablishmentDetail): string => {
	const address: string = getAddress(est);
	return est.businessName + (address ? ", " + address : "");
};
