import React from "react";
import { useQuery } from "@apollo/client";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import Head from "../../components/head/head.component";
import {
	CountryStatsData,
	CountryStatsInput,
} from "../../components/types/types";
import { COUNTRY_STATS_QUERY } from "../../gql/country-stats.query";
import { percentage } from "../../util/math";
import { Link } from "react-router-dom";
import AdUnit from "../../components/ads/ad-unit.component";

const HowTo5StarRatingPage: React.FC = () => {
	const { data, error, loading } = useQuery<
		CountryStatsData,
		CountryStatsInput
	>(COUNTRY_STATS_QUERY, {
		fetchPolicy: "no-cache",
		nextFetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
	});

	if (loading) return <Loading fullscreen={true} />;

	if (error || !data) return <NoData />;

	const totalEstablishmentsEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalEstablishments,
		0
	);
	const totalFiveRatedEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalFiveRated,
		0
	);
	const percentageFiveRatedEWNI = percentage(
		totalFiveRatedEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const title = "Boost Your Business: How to Earn a 5-Star Food Hygiene Rating";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					Want to boost your food business and increase your profits?&nbsp;
					Achieving a 5-star hygiene rating can help get you there.
				</p>
				<p>
					You see, post-covid, consumers are increasingly concerned about food
					hygiene and safety.&nbsp; But a 5-star rating can give them the
					confidence to do business with you.
				</p>

				<AdUnit slotId={"9267079688"} />

				<p>
					According to recent data from the Food Standards Agency,{" "}
					<strong>
						{percentageFiveRatedEWNI}% of businesses have a 5-star rating.
					</strong>
				</p>
				<p>So how can you become part of that statistic?</p>
				<p>Well, that's what you're going to learn in this article.</p>
				<p>
					First of all you'll learn how to get an inspection.&nbsp; Then we'll
					take a look at what you need to do to prepare for an inspection.&nbsp;
				</p>
				<p>
					Finally we'll look at what to expect on the day and what sort of
					questions you may get asked.
				</p>
				<p>Let's get to it.</p>

				<AdUnit slotId={"8621046578"} />

				<h2>
					<strong>How to get an inspection</strong>
				</h2>
				<p>
					Before you can receive an inspection, you need to register with your
					local council as a food business.
				</p>
				<p>
					Then, once you have registered your business, officers will carry out
					an inspection.&nbsp; They will then give you a rating.
				</p>
				<p>
					Generally it is up to the officer carrying out the inspection to
					decide when it will take place.&nbsp; In other words, officers will
					usually turn up unannounced.
				</p>
				<p>
					But if your food business is home-based, then they may contact you to
					arrange a suitable date and time.
				</p>
				<p>
					I've included a link to the food business registration webpage on my{" "}
					<Link to="/resources">resources page.</Link>
				</p>

				<AdUnit slotId={"5994883232"} />

				<h2>
					<strong>Things to do before an inspection</strong>
				</h2>
				<p>
					On the day of the inspection, the officer will ask to see the
					paperwork that you have in place.
				</p>
				<p>
					So, you need to have all that in place{" "}
					<strong>before the inspection takes place.</strong>
				</p>
				<p>
					The first thing you're going to need is your{" "}
					<strong>level 2 food safety and hygiene.</strong>
				</p>
				<p>
					You should do this course ideally <strong>before registering</strong>{" "}
					your business.
				</p>
				<p>
					You can find a link to this course on my{" "}
					<Link to="/resources">resources page.</Link>
				</p>
				<p>
					Another recommended course is the food allergen course.&nbsp; This is
					a free course provided by the Food Standards Agency.&nbsp;
				</p>
				<p>
					Again, this link is also on my{" "}
					<Link to="/resources">resources page.</Link>
				</p>

				<AdUnit slotId={"9592165706"} />

				<h3>
					<strong>Paperwork</strong>
				</h3>
				<p>
					One of the most important parts of the inspection is the paperwork you
					have in place.
				</p>
				<p>
					Paperwork consists of the systems, procedures and checklists your
					business has in place.
				</p>
				<p>
					Good paperwork is a must because it backs up what you say to the
					inspection officer.
				</p>
				<p>
					It also means you have repeatable processes that help to reduce
					risk.&nbsp; It also helps to reduce mistakes that may affect food
					hygiene.
				</p>
				<p>
					The most important paperwork for inspections is the{" "}
					<strong>“safer food, better business pack”.</strong>
				</p>
				<p>
					This pack is free to download or you can buy it online from
					Amazon.&nbsp; The links to these are on my{" "}
					<Link to="/resources">resources page.</Link>
				</p>

				<AdUnit slotId={"8279084037"} />

				<p>
					<strong>
						This pack is a must.&nbsp; It contains everything you need to know
						and you should also keep it within your premises at all times.
					</strong>
				</p>
				<p>
					If you want a 5-star rating, you need to fill it out and know it
					inside out.
				</p>
				<p>
					During the inspection, it is one of the first things your officer will
					ask about and ask to see.
				</p>
				<p>
					The pack is very useful in that it tells you what you should do to
					maintain good hygiene standards.&nbsp; The pack also lists the
					reasoning behind certain things.
				</p>
				<p>
					There's also space next to each item that allows you to write down
					what you actually do.&nbsp; For example, it may say “staff should
					change their aprons after handling raw meat, poultry etc".
				</p>
				<p>
					You could then write next to it “staff use separate brown aprons when
					handling raw food" for example.
				</p>

				<AdUnit slotId={"4681801567"} />

				<p>
					The pack itself is very comprehensive and leaves no stone
					unturned.&nbsp; But it's essential that you go through it all.
				</p>
				<p>
					Some of what's contained within the pack may not be applicable to your
					business.&nbsp;
				</p>
				<p>
					For example, there's information in the pack about the freezing of
					food.&nbsp; If you don't freeze food, then some of the info won't be
					applicable.&nbsp;
				</p>
				<p>
					But you still need to write down in the pack that you don't freeze
					food.&nbsp; In other words,{" "}
					<strong>the paperwork needs to back up what you're saying.</strong>
				</p>
				<p>
					The role of the inspector is to check what you have written down is
					actually happening in your business.
				</p>

				<AdUnit slotId={"1225772286"} />

				<h3>
					<strong>Other important paperwork</strong>
				</h3>
				<p>
					Before you start trading, make sure you have{" "}
					<strong>public liability insurance.&nbsp;</strong> During your
					inspection, prepare to show your certificate to the inspector.
				</p>
				<p>
					You also need to have done your HACCP.&nbsp; This stands for Hazard
					Analysis and Critical Control Point.
				</p>
				<p>
					HACCP is a way of managing food safety hazards.&nbsp; It also allows
					you to highlight potential problems.&nbsp; An example may be a certain
					product that 'goes off' after a few days of being in the fridge.
				</p>
				<p>
					There is a free web tool on the Food Standards Agency website called
					the <strong>MyHACCP</strong>.&nbsp; This tool helps you create your
					own food safety management system.
				</p>

				<p>
					The link to the tool is on my{" "}
					<Link to="/resources">resources page.</Link>
				</p>

				<AdUnit slotId={"6860392285"} />

				<p>
					One final potential gotcha is to do with the waste that your business
					produces.
				</p>
				<p>
					When it comes to waste, you can have it collected by a source.&nbsp;
					Or you can dispose of it yourself.
				</p>
				<p>
					But, to dispose of waste yourself, you need to{" "}
					<strong>register as a waste carrier.</strong>
				</p>
				<p>
					I've included the link on my{" "}
					<Link to="/resources">resources page</Link> which allows you to
					register as a waste carrier.
				</p>
				<p>
					If this is the case, you need to be able to show the officer some sort
					of certification to prove it.
				</p>
				<p>
					If you have a third-party collect your waste, you need to document
					this too.&nbsp; That way you can prove to the inspection officer that
					you have the procedures in place to manage waste.
				</p>

				<AdUnit slotId={"6966002366"} />

				<h2>
					<strong>What to expect on the day of the inspection</strong>
				</h2>
				<p>
					On the day of the inspection, it's normal to be a bit apprehensive and
					nervous.&nbsp; It shows you care about good food hygiene and your
					business.
				</p>
				<p>
					On the day, expect the officer to have a good look around your
					business.&nbsp; He or she may ask you questions whilst doing so.&nbsp;
					They may also take photographs of things.
				</p>
				<p>
					They may also ask you to walk them through a typical day in your
					business.
				</p>
				<p>
					As the inspector asks you questions, they will ask you to prove that
					you have things written down.&nbsp;
				</p>
				<p>
					For example, they may ask you if you freeze food.&nbsp; But even if
					you don't freeze food, they will ask you to show you that you have
					written this down.
				</p>
				<p>
					In other words, the paperwork will need you to back-up what you're
					saying.
				</p>

				<AdUnit slotId={"8912690611"} />

				<h2>
					<strong>What do food inspectors look for?</strong>
				</h2>
				<p>
					Food inspectors look for a lot of things but their main goal is to see
					if food is being handled hygienically.&nbsp;
				</p>
				<p>
					They also want to find out if your food is safe to eat and identify
					potential risks that could cause harm to the public.
				</p>
				<p>
					During an inspection, a food safety officer will score a business in
					each of the following areas.
				</p>
				<p>
					<strong>
						Note: To achieve the highest rating, your business must perform well
						in each area.
					</strong>
				</p>

				<AdUnit slotId={"4411157068"} />

				<h3>
					<strong>Hygienic food handling</strong>
				</h3>
				<p>
					Inspectors examine how well the establishment prepares and handles
					food.
				</p>
				<p>
					They also look at how the business deals with the cooking, re-heating,
					cooling and the storage of food.
				</p>
				<p>
					Inspections also cover things like temperature control and personal
					hygiene.&nbsp; In other words, how clean the chefs are working
					(washing of hands etc).
				</p>
				<p>
					Finally, inspections examine how well a business prevents
					cross-contamination.
				</p>

				<AdUnit slotId={"9742556550"} />

				<h4>Allergens</h4>
				<p>
					One area that inspectors are big on is <strong>allergens</strong>.
				</p>
				<p>
					So, make sure you have an allergy list available for customers to
					see.&nbsp; If a customer has an allergy, they will want to know what
					dishes they can order.
				</p>
				<p>
					You can make allergen lists yourself or they're downloadable from
					Etsy.
				</p>
				<p>
					It's also a good idea to have allergen awareness posters in the
					kitchen available for staff to refer to.
				</p>

				<AdUnit slotId={"7599608949"} />

				<p>Here's some extra things to be aware of:</p>
				<ul>
					<li>
						If you serve gluten-free foods for example, be sure to use separate
						equipment.&nbsp; You should also store that equipment separately.
					</li>
				</ul>
				<ul>
					<li>
						An inspector may ask you to gather all the ingredients in one of
						your dishes.&nbsp; They may then check each ingredient for allergens
						and cross check with your list.
					</li>
				</ul>
				<ul>
					<li>
						Inspectors will also want to see that you have taken a course on
						allergens.&nbsp; So be ready to show any certificates that you may
						have.
					</li>
				</ul>
				<p>
					If you deliver food, label the food with allergen stickers.&nbsp; This
					should also contain a link to your websites' terms and conditions.
				</p>
				<p>
					Finally, if any of your ingredients contain allergens, be sure to
					write them on a label.&nbsp; Then attach it to the container.&nbsp;
					This isn't necessary if you keep ingredients in their original
					packaging.
				</p>

				<AdUnit slotId={"5547310613"} />

				<h3>
					<strong>Cleanliness and condition of facilities and building</strong>
				</h3>
				<p>
					In this area of inspection, officers examine the physical condition of
					the building.
				</p>
				<p>
					They look to see how good the structure is and how well it's
					maintained.&nbsp; Besides the cleanliness, the following factors are
					also considered:
				</p>
				<ul>
					<li>Building layout</li>
				</ul>
				<ul>
					<li>Building lighting</li>
				</ul>
				<ul>
					<li>Building ventilation</li>
				</ul>
				<ul>
					<li>Pest control</li>
				</ul>
				<ul>
					<li>Any other facilities</li>
				</ul>

				<AdUnit slotId={"7403333674"} />

				<h3>
					<strong>Management of food safety</strong>
				</h3>
				<p>
					This area examines the processes and procedures that are in place to
					ensure food safety.
				</p>
				<p>
					This gives the officer an idea of how likely a business is to maintain
					standards in the future.
				</p>
				<p>
					Much of this comes down to having good paperwork, which we have looked
					at before.
				</p>

				<AdUnit slotId={"4071427473"} />

				<h2>
					<strong>Questions food hygiene inspectors may ask</strong>
				</h2>
				<p>
					Food hygiene inspectors check to ensure that establishments follow
					proper food safety guidelines.
				</p>
				<p>
					To ensure compliance with these guidelines, inspectors will ask a
					variety of questions.
				</p>
				<p>Here's some questions that they may ask on the day of inspection:</p>
				<ul>
					<li>Do you serve meat?</li>
				</ul>
				<ul>
					<li>What food do you make?</li>
				</ul>
				<ul>
					<li>Do you outsource to suppliers?</li>
				</ul>
				<ul>
					<li>Do you have a list of suppliers?</li>
				</ul>
				<ul>
					<li>Do you handle fresh cream?</li>
				</ul>
				<ul>
					<li>Do you handle fruit?</li>
				</ul>
				<ul>
					<li>Where are cleaning products kept?</li>
				</ul>

				<AdUnit slotId={"5327834679"} />

				<ul>
					<li>
						What is the fridge situation?&nbsp; Is there a chance of
						cross-contamination?
					</li>
				</ul>
				<ul>
					<li>
						Do you have two basins, one for washing up, the other for
						handwashing?
					</li>
				</ul>
				<ul>
					<li>What sink will you use for washing fruit and veg?</li>
				</ul>
				<ul>
					<li>
						How do customers receive their orders (contactless delivery or
						collection)?
					</li>
				</ul>
				<ul>
					<li>How many times do you wash dishcloths?</li>
				</ul>
				<ul>
					<li>Questions about pest control</li>
				</ul>
				<ul>
					<li>Questions about waste, what do you do with it?</li>
				</ul>
				<ul>
					<li>How much soap do you put in a mop bucket?&nbsp;</li>
				</ul>
				<ul>
					<li>Where are your gloves, aprons and PPE stored?</li>
					<li>Do you have a suppliers list?</li>
				</ul>

				<h2>
					<strong>Before you go…</strong>
				</h2>
				<p>
					The information in this article is a great start.&nbsp; But I highly
					recommend reading my companion article.&nbsp; It's almost like a
					part-2 to this one with lots of non-obvious food hygiene tips to help
					you get that 5-star rating…
				</p>
				<p>
					<Link to="/blog/food-hygiene-tips">
						Boost your hygiene rating with these proven food hygiene tips
					</Link>
				</p>

				<AdUnit slotId={"3026757357"} />
			</div>
		</div>
	);
};

export default HowTo5StarRatingPage;
