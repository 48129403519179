import React from "react";

import "./input.component.scss";

interface Props {
	name: string;
	placeholder: string;
	requiredField: boolean;
	handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextField: React.FC<Props> = ({
	name,
	placeholder,
	requiredField,
	handleChange,
}) => {
	return (
		<div className="form-control">
			<input
				type="text"
				name={name}
				placeholder={placeholder}
				required={requiredField}
				onChange={handleChange}
			></input>
		</div>
	);
};

export default TextField;
