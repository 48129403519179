import React from "react";
import { useQuery } from "@apollo/client";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import Head from "../../components/head/head.component";
import { CountryStatsCombinedData } from "../../components/types/types";
import { percentage } from "../../util/math";
import { COUNTRY_STATS_COMBINED_QUERY } from "../../gql/country-stats-combined.query";
import { Link } from "react-router-dom";
import AdUnit from "../../components/ads/ad-unit.component";

const ZeroFoodHygieneRatingsPage: React.FC = () => {
	const { data, error, loading } = useQuery<CountryStatsCombinedData>(
		COUNTRY_STATS_COMBINED_QUERY,
		{
			fetchPolicy: "no-cache",
			nextFetchPolicy: "no-cache",
			notifyOnNetworkStatusChange: true,
		}
	);

	if (loading) return <Loading fullscreen={true} />;

	if (error || !data) return <NoData />;

	const totalEstablishmentsEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalEstablishments,
		0
	);

	const totalEstablishmentsScotland = data.countryStatsScotland.reduce(
		(acc, country) => acc + country.totalEstablishments,
		0
	);

	const totalZeroRatedEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalZeroRated,
		0
	);

	const totalImprovmentRequiredRatedScotland = data.countryStatsScotland.reduce(
		(acc, country) => acc + country.totalImprovementRequired,
		0
	);

	const percentageZeroRated = percentage(
		totalZeroRatedEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const percentageImprovementRequired = percentage(
		totalImprovmentRequiredRatedScotland,
		totalEstablishmentsScotland
	).toFixed(2);

	const title = "Zero Food Hygiene Ratings: Everything You Need To Know";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					Zero food hygiene ratings are not something that any food business
					wants to receive.&nbsp;
				</p>
				<p>
					And it's probably a fair bet to assume that most people would probably
					avoid eating at these places too.
				</p>

				<AdUnit slotId={"6008143918"} />

				<p>
					But what does a 'zero' rating actually mean, and is it really unsafe
					to eat there?&nbsp; Well, stay tuned, because this article answers
					these questions and more.
				</p>
				<p>Let's go!</p>
				<h2>What does a zero food hygiene rating mean?</h2>
				<p>
					A food hygiene rating of 'zero' is at the bottom of the food hygiene
					ratings scale.&nbsp; In short, it means 'urgent improvement' is
					necessary.
				</p>
				<p>
					A zero rating is the lowest rating that a business can receive after a
					food hygiene inspection.&nbsp; It means there's serious problems when
					it comes to food safety and hygiene practices.&nbsp;
				</p>
				<p>
					A business would then need to take immediate action to bring things up
					to standard.
				</p>
				<p>
					To arrive at an overall rating for the business, food safety officers
					look into several areas.&nbsp; These include the handling,
					preparation, cooking and storage of food.
				</p>

				<AdUnit slotId={"1279357573"} />

				<p>
					They also consider the cleanliness of the facilities used to prepare
					food.&nbsp; And they inspect the condition of the premises.
				</p>
				<p>
					Confidence in management and the procedures they use are also checked.
				</p>
				<p>
					To determine the hygiene rating, officers add the scores together from
					each category.
				</p>
				<p>
					The higher the score the worse the establishment has performed at the
					time of inspection.
				</p>

				<AdUnit slotId={"8141700276"} />

				<p>
					This final score is then used to determine the hygiene rating for the
					business.
				</p>
				<p>
					Establishments that score 50 or more will get a hygiene rating of
					zero.
				</p>
				<p>
					You can find out more about{" "}
					<Link to="/blog/ratings-explained">
						how the food hygiene rating is calculated here.
					</Link>
				</p>

				<AdUnit slotId={"7653194235"} />

				<h2>How bad is a zero food hygiene rating?</h2>
				<p>
					A zero food hygiene rating is bad for any business.&nbsp; But it may
					not be bad for the end customer.&nbsp; That's because a zero hygiene
					rating doesn't necessarily mean an establishment is 'bad'.
				</p>
				<p>
					It also doesn't mean that the food an establishment provides is not
					fit for human consumption.
				</p>
				<p>
					In fact, you can get some very clean food establishments with a zero
					food hygiene rating.&nbsp;
				</p>

				<AdUnit slotId={"6555734983"} />

				<p>
					During a hygiene rating inspection, establishments are typically
					evaluated in three main areas.&nbsp; Only one of those areas is
					hygienic food handling.&nbsp; The other two areas are:
				</p>

				<ul>
					<li>Cleanliness and condition of facilities and building</li>
					<li>
						The management of food safety.&nbsp; This includes the
						procedures/paperwork a business has in place.
					</li>
				</ul>
				<p>
					But, some businesses can achieve a zero hygiene rating because of
					hygiene issues.&nbsp; Or they may have failings across all three
					areas.&nbsp;
				</p>
				<p>
					Some of these establishments may have cross-contamination issues and
					pest infestations.
				</p>
				<p>
					So, before you eat, it's wise to check the hygiene rating.&nbsp; But
					you should also look at the individual scores for the three main areas
					that make up the rating.
				</p>
				<p>
					You can find out the hygiene rating of any food business on the Food
					Standards Agency website.&nbsp; Or you can find them here on Food Owl.
				</p>

				<AdUnit slotId={"6340112566"} />

				<h2>Is it safe to eat at a zero food hygiene establishment?</h2>
				<p>
					If authorities haven't ordered the business to shut down and stop
					trading, then it is safe to eat there.
				</p>
				<p>
					Not all zero food hygiene establishments produce food that is unsafe
					to eat.&nbsp;
				</p>
				<p>
					If there is an imminent risk to the public, then the authorities have
					the power to shut down part or all the business.
				</p>

				<AdUnit slotId={"1893639083"} />

				<h2>What happens if you get a zero food hygiene rating</h2>
				<p>
					If a business receives a zero hygiene rating, they must make
					improvements urgently.
				</p>
				<p>
					The authorities will then provide advice and guidance on how to
					improve.&nbsp; They will also tell the business the required
					timescales for these improvements.
				</p>
				<p>
					The business will continue to have a zero hygiene rating until their
					next inspection.&nbsp; Or until they are re-rated.&nbsp; This usually
					happens within one month.
				</p>
				<p>
					Then, if the business has improved sufficiently, they'll receive a
					new, improved rating.
				</p>

				<AdUnit slotId={"9580557419"} />

				<p>
					In the meantime, a food business can suffer financially due to the low
					rating.&nbsp; It can also affect a company's reputation, particularly
					if it makes the local news.&nbsp; This can further affect the bottom
					line.
				</p>
				<p>
					The general public are aware of the Food Hygiene Rating Scheme.&nbsp;
					As such, if they see a restaurant or a takeaway with a zero rating,
					they will assume that it is not safe to eat in.
				</p>
				<h2>What powers do food enforcement officers have?</h2>
				<p>
					Local councils only have the power to shut down premises if there is
					an imminent risk to public health.
				</p>
				<p>
					If there is a risk to public health, authorities may order the part or
					all the business to close down.&nbsp;
				</p>

				<AdUnit slotId={"6954394071"} />

				<h2>How to avoid a zero food hygiene rating</h2>
				<p>
					Following good food hygiene practices is essential in avoiding a poor
					hygiene rating.
				</p>
				<p>This includes (but not limited to):</p>
				<ul>
					<li>
						Handling, preparing and storing food safely to prevent
						cross-contamination
					</li>
				</ul>
				<ul>
					<li>
						Maintaining a clean and hygienic environment.&nbsp; Cleaning
						equipment, surfaces, and floors regularly.
					</li>
				</ul>
				<ul>
					<li>
						Ensuring that all staff have the necessary knowledge/training to
						handle food safely.
					</li>
				</ul>
				<ul>
					<li>Implementing effective pest control measures.</li>
				</ul>
				<ul>
					<li>
						Having a documented food safety management system in place and
						following it consistently.&nbsp; The 'safe food better business'
						pack on my <Link to="/resources">resources page</Link> is a great
						place to start.
					</li>
				</ul>
				<ul>
					<li>Regularly checking and maintaining food safety records.</li>
				</ul>
				<p>
					For more in-depth advice, read my article on{" "}
					<Link to="/blog/how-to-five-star-rating">
						How to Earn a 5-Star Food Hygiene Rating.
					</Link>
				</p>

				<AdUnit slotId={"3713949225"} />

				<h2>How many zero rated establishments are in the UK?</h2>
				<p>
					In England, Wales and Northern Ireland, there are{" "}
					<strong>{totalEstablishmentsEWNI.toLocaleString()}</strong> food
					businesses registered with the Food Standards Agency.
				</p>
				<p>
					Out of those,{" "}
					<strong>
						a total of {totalZeroRatedEWNI.toLocaleString()} businesses
					</strong>{" "}
					have achieved a rating of zero{" "}
					<strong>({percentageZeroRated}%).</strong>
				</p>

				<AdUnit slotId={"2400867553"} />

				<h2>What's the equivalent of a zero rating in Scotland?</h2>
				<p>
					In England, Wales and Northern Ireland, authorities use the Food
					Hygiene Information scheme.
				</p>
				<p>
					Scotland uses a different scheme called the Food Hygiene Information
					Scheme (FHIS).
				</p>
				<p>
					Although the schemes are similar, they have different rating scales.
				</p>
				<p>
					Businesses in Scotland either receive a 'Pass', 'Improvement Required'
					or are 'Exempt'.
				</p>

				<AdUnit slotId={"9388985724"} />

				<p>
					A 'pass' simply means that the business has met the legal requirements
					for food hygiene.
				</p>
				<p>
					The 'Improvement Required' rating indicates that the business did not
					meet legal requirements.&nbsp; These businesses must take steps to
					improve their food hygiene practices.
				</p>
				<p>
					In Scotland, there are{" "}
					<strong>{totalEstablishmentsScotland.toLocaleString()}</strong> food
					businesses registered with Food Standards Scotland.
				</p>
				<p>
					Out of those, a total of{" "}
					<strong>
						{totalImprovmentRequiredRatedScotland.toLocaleString()}
					</strong>{" "}
					businesses have achieved a rating of 'Improvement Required'{" "}
					<strong>({percentageImprovementRequired}%).</strong>
				</p>

				<AdUnit slotId={"8990326634"} />

				<h2>The bottom line</h2>
				<p>
					When considering somewhere to eat, it's important to look at the
					hygiene rating.&nbsp; Don't just rely on your gut instinct or your own
					judgment.&nbsp;
				</p>
				<p>
					The hygiene rating is based on the experienced, professional opinion
					of the environmental officer.
				</p>
				<p>
					One final tip is to look out for the rating near the entrance to a
					food establishment.&nbsp;
				</p>
				<p>
					In Wales and Northern Ireland it is mandatory for businesses to
					display their rating.&nbsp; But in England it isn't.&nbsp; But
					businesses with a five star rating are likely to display their rating
					somewhere.&nbsp;
				</p>
				<p>
					Businesses that have a zero rating are less likely to display their
					rating.&nbsp; But you can check online.&nbsp; Bear in mind that some
					large brands may also choose not to display their rating.&nbsp; One
					reason for this is that it may not fit with their branding.
				</p>

				<AdUnit slotId={"6762822389"} />
			</div>
		</div>
	);
};

export default ZeroFoodHygieneRatingsPage;
