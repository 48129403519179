import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/head/head.component";

const AboutPage: React.FC = () => {
	const title = "About Food Owl";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					I created Food Owl initially as an exercise in learning new
					technologies such as{" "}
					<a
						href="https://reactjs.org/"
						target="_blank"
						rel="noopener noreferrer"
					>
						React.js
					</a>{" "}
					and
					<a
						href="https://graphql.org/"
						target="_blank"
						rel="noopener noreferrer"
					>
						{" "}
						GraphQL
					</a>{" "}
					but I also wanted to create something useful that I myself would like
					to use, so I created this website.
				</p>

				<p>
					Food Owl reveals the food hygiene ratings for all relevant businesses
					in the UK using data obtained from the&nbsp;
					<a
						href="https://ratings.food.gov.uk/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Food Standards Agency.
					</a>
				</p>

				<p>
					You can search for a particular business (like your favourite
					restaurant or takeaway) on the&nbsp;
					<Link to="/">home page</Link>. But you can also look at the different
					types of businesses to help you find the very best eateries in your
					local area.
				</p>

				<p>
					If you'd like to contact me to give feedback, suggest improvements or
					anything else then&nbsp;
					<a href="mailto:foodowlapp@gmail.com">email me.</a>
				</p>

				<p>
					I hope you enjoy using this service.
					<span role="img" aria-label="smiley">
						&nbsp;🙂
					</span>
				</p>
			</div>
		</div>
	);
};

export default AboutPage;
