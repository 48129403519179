import { Authority, BusinessType, Establishment } from "../types/types";

export const getAuthorityById = (authorityId: string, authorityList: Authority[]): Authority | undefined => {
	const authority: Authority | undefined =
		authorityList.find(
			(auth) => auth.localAuthorityId === authorityId
		);
	return authority;
};

export const getAuthorityBySlug = (authoritySlug: string, authorityList: Authority[]): Authority | undefined => {
	const authority: Authority | undefined =
		authorityList.find(
			(auth) => auth.slug === authoritySlug
		);
	return authority;
};

export const getBusinessTypeBySlug = (businessTypeSlug: string, businessTypeList: BusinessType[]): BusinessType | undefined => {
	const businessType: BusinessType | undefined =
		businessTypeList.find(
			(businessType) => businessType.slug === businessTypeSlug
		);
	return businessType;
};

export const getAddress = (est: Establishment): string => {
	return [
		est.addressLine1,
		est.addressLine2,
		est.addressLine3,
		est.addressLine4,
		est.postcode,
	]
		.filter(Boolean)
		.join(", ");
};