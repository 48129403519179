import { gql } from "@apollo/react-hooks";

export const AUTHORITY_LIST_QUERY = gql`
	query GetAuthorities($regionSlug: String, $authoritySlug: String, $limit: Int!) {
		authorityList(input: {regionSlug: $regionSlug, authoritySlug: $authoritySlug, limit: $limit}) {
			authorities {
				id
				name
				localAuthorityId
				slug
				region {
					regionId
					name
					slug
				}
			}
		}
	}
`;