import { gql } from "@apollo/react-hooks";

export const REGION_LIST_QUERY = gql`
	query GetRegions {
		regions {
			id
			name
			regionId
			slug
		}
	}
`;