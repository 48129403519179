import React from "react";
import { Link } from "react-router-dom";
import "./no-data.component.scss";

const NoData: React.FC = () => {
	return (
		<div className="container panel">
			<div className="article">
				<h1 className="text-bold">Uh Oh!</h1>
				<p>
					Sorry, we can't find what you're looking for this time. Please&nbsp;
					<Link to={"/"}>try again</Link> with a different search term.
				</p>
			</div>
		</div>
	);
};

export default NoData;
