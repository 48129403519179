import React from "react";
import { Link } from "react-router-dom";
import "./navbar.component.scss";

const NavBar: React.FC = () => {
	return (
		<div className="navbar">
			<div className="container flex">
				<h1 className="navbar__logo">
					<Link to="/">
						<span className="text-bold">Food</span>Owl
					</Link>
				</h1>
				<nav className="navbar__links">
					<ul>
						<li>
							<Link to="/news">News</Link>
						</li>
						<li>
							<Link to="/blog">Blog</Link>
						</li>
						<li>
							<Link to="/resources">Resources</Link>
						</li>
						<li>
							<Link to="/about">About</Link>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};
export default NavBar;
