import React from "react";
import AdUnit from "../ads/ad-unit.component";
import { StatsPageData } from "../types/types";

interface Props {
	data: StatsPageData;
}

const AuthorityStats: React.FC<Props> = ({ data }) => {
	const worstAuthorityEWNI = data.bottomAuthorityStatsEWNI[0];
	const bestAuthorityEWNI = data.topAuthorityStatsEWNI[0];

	const worstAuthorityScotland = data.bottomAuthorityStatsScotland[0];
	const bestAuthorityScotland = data.topAuthorityStatsScotland[0];

	const totalZeroOneRatedEstablishments =
		worstAuthorityEWNI.totalOneRated + worstAuthorityEWNI.totalZeroRated;

	return (
		<>
			<h2>
				Local Authority Level Statistics (England, Wales and Northern Ireland)
			</h2>
			<p>
				In this section, we'll look at the best and worst performing local
				authorities for food hygiene ratings in England, Wales and Northern
				Ireland.
			</p>
			<ul>
				<li>
					The <strong>worst performing local authority</strong> out of England,
					Wales and Northern Ireland for food hygiene ratings is{" "}
					<strong>{worstAuthorityEWNI.localAuthorityName}</strong>.
				</li>
				<p>
					<strong>{worstAuthorityEWNI.localAuthorityName}</strong> currently has
					the{" "}
					<strong>
						greatest percentage of zero and one-rated food establishments.
					</strong>
					There are a total of{" "}
					<strong>
						{worstAuthorityEWNI.totalEstablishments.toLocaleString()}
					</strong>{" "}
					food-based businesses in{" "}
					<strong>{worstAuthorityEWNI.localAuthorityName}</strong> and{" "}
					<strong>{totalZeroOneRatedEstablishments}</strong> of those are rated
					'zero' or 'one'{" "}
					<strong>
						({worstAuthorityEWNI.percentageOneZeroRated.toFixed(2)}%).
					</strong>
				</p>
				<li>
					<strong>The best performing local authority</strong> out of England,
					Wales and Northern Ireland for food hygiene ratings is{" "}
					<strong>{bestAuthorityEWNI.localAuthorityName}.</strong>
				</li>
			</ul>
			<p>
				<strong>{bestAuthorityEWNI.localAuthorityName}</strong> currently has
				the{" "}
				<strong>greatest percentage of five-rated food establishments. </strong>
				There are a total of{" "}
				<strong>
					{bestAuthorityEWNI.totalEstablishments.toLocaleString()}
				</strong>{" "}
				food-based businesses in{" "}
				<strong>{bestAuthorityEWNI.localAuthorityName}</strong> and{" "}
				<strong>{bestAuthorityEWNI.totalFiveRated.toLocaleString()}</strong> of
				those have a rating of 'five'{" "}
				<strong>({bestAuthorityEWNI.percentageFiveRated.toFixed(2)}%).</strong>
			</p>

			<AdUnit slotId={"9192107816"} />

			<h3>Worst performing local authorities for food hygyiene ratings</h3>

			<p>
				Here's a table displaying the{" "}
				<strong>
					bottom {data.bottomAuthorityStatsEWNI.length} local authorities for
					food hygiene
				</strong>{" "}
				ratings in <strong>England, Wales and Northern Ireland.</strong>&nbsp;
				This highlights the local authorities where improvement is required to
				catch-up with the local authorities that perform the best in the UK.
			</p>

			<div className="responsive-table">
				<table>
					<tbody>
						<tr>
							<th>
								<strong>Local Authority</strong>
							</th>
							<th>
								<strong>0 and 1-Rated Businesses (%)</strong>
							</th>
						</tr>
						{data.bottomAuthorityStatsEWNI.map((authorityStats, index) => {
							return (
								<tr key={index}>
									<td>{authorityStats.localAuthorityName}</td>
									<td>
										{authorityStats.percentageOneZeroRated.toFixed(2)}% (
										{authorityStats.totalOneRated +
											authorityStats.totalZeroRated}
										)
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<AdUnit slotId={"8033270510"} />

			<h3>Best performing local authorities for food hygyiene ratings</h3>

			<p>
				Here's a table displaying the{" "}
				<strong>
					top {data.topAuthorityStatsEWNI.length} local authorities for food
					hygiene
				</strong>{" "}
				ratings in <strong>England, Wales and Northern Ireland.</strong>&nbsp;
				This highlights their impressive efforts in maintaining high standards
				in the UK food industry.
			</p>

			<div className="responsive-table">
				<table>
					<tbody>
						<tr>
							<th>
								<strong>Local Authority</strong>
							</th>
							<th>
								<strong>5-Rated Businesses (%)</strong>
							</th>
						</tr>
						{data.topAuthorityStatsEWNI.map((authorityStats, index) => {
							return (
								<tr key={index}>
									<td>{authorityStats.localAuthorityName}</td>
									<td>
										{authorityStats.percentageFiveRated.toFixed(2)}% (
										{authorityStats.totalFiveRated})
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<AdUnit slotId={"7028978900"} />

			<h2>Scottish Local Authority Level Statistics</h2>

			<p>
				Finally, we'll look at the best and worst performing local authorities
				for food hygiene ratings in Scotland.
			</p>
			<ul>
				<li>
					The <strong>worst performing local authority</strong> in Scotland for
					food hygiene ratings is{" "}
					<strong>{worstAuthorityScotland.localAuthorityName}</strong>.
				</li>
				<p>
					<strong>{worstAuthorityScotland.localAuthorityName}</strong> currently
					has the{" "}
					<strong>
						greatest percentage of food establishments with 'Improvement
						Required'.{" "}
					</strong>
					There are a total of{" "}
					<strong>
						{worstAuthorityScotland.totalEstablishments.toLocaleString()}
					</strong>{" "}
					food-based businesses in{" "}
					<strong>{worstAuthorityScotland.localAuthorityName}</strong> and{" "}
					<strong>
						{worstAuthorityScotland.totalImprovementRequired.toLocaleString()}
					</strong>{" "}
					of those are rated as 'Improvement Required'{" "}
					<strong>
						(
						{worstAuthorityScotland.percentageImprovementRequiredRated.toFixed(
							2
						)}
						%).
					</strong>
				</p>
				<li>
					<strong>The best performing local authority</strong> in Scotland for
					food hygiene ratings is{" "}
					<strong>{bestAuthorityScotland.localAuthorityName}.</strong>
				</li>
			</ul>
			<p>
				<strong>{bestAuthorityScotland.localAuthorityName}</strong> currently
				has the{" "}
				<strong>greatest percentage of pass-rated food establishments.</strong>{" "}
				There are a total of{" "}
				<strong>{bestAuthorityScotland.totalEstablishments}</strong> food-based
				businesses in{" "}
				<strong>{bestAuthorityScotland.localAuthorityName}</strong> and{" "}
				<strong>{bestAuthorityScotland.totalPass}</strong> of those have a
				rating of 'pass'{" "}
				<strong>
					({bestAuthorityScotland.percentagePassRated.toFixed(2)}%).
				</strong>
			</p>

			<AdUnit slotId={"9358810533"} />

			<h3>
				Worst performing Scottish local authorities for food hygyiene ratings
			</h3>

			<p>
				Here's a table displaying the{" "}
				<strong>
					bottom {data.bottomAuthorityStatsScotland.length} local authorities
					for food hygiene
				</strong>{" "}
				ratings in <strong>Scotland.</strong>&nbsp; This highlights the local
				authorities where improvement is required to catch-up with the local
				authorities that perform the best in Scotland.
			</p>

			<div className="responsive-table">
				<table>
					<tbody>
						<tr>
							<th>
								<strong>Local Authority</strong>
							</th>
							<th>
								<strong>'Improvement Required' Businesses (%)</strong>
							</th>
						</tr>
						{data.bottomAuthorityStatsScotland.map((authorityStats, index) => {
							return (
								<tr key={index}>
									<td>{authorityStats.localAuthorityName}</td>
									<td>
										{authorityStats.percentageImprovementRequiredRated.toFixed(
											2
										)}
										% (
										{authorityStats.totalImprovementRequired.toLocaleString()})
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<AdUnit slotId={"2626699466"} />

			<h3>
				Best performing Scottish local authorities for food hygyiene ratings
			</h3>

			<p>
				Here's a table displaying the{" "}
				<strong>
					top {data.topAuthorityStatsScotland.length} local authorities for food
					hygiene
				</strong>{" "}
				ratings in <strong>Scotland.</strong>&nbsp; This highlights their
				impressive efforts in maintaining high standards in the Scottish food
				industry.
			</p>

			<div className="responsive-table">
				<table>
					<tbody>
						<tr>
							<th>
								<strong>Local Authority</strong>
							</th>
							<th>
								<strong>'Pass' Businesses (%)</strong>
							</th>
						</tr>
						{data.topAuthorityStatsScotland.map((authorityStats, index) => {
							return (
								<tr key={index}>
									<td>{authorityStats.localAuthorityName}</td>
									<td>
										{authorityStats.percentagePassRated.toFixed(2)}% (
										{authorityStats.totalPass.toLocaleString()})
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default AuthorityStats;
