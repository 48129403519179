import { gql } from "@apollo/react-hooks";

export const GET_ESTABLISHMENTS_BY_BUSINESS_TYPE_QUERY = gql`
	query GetEstablishmentsByBusinessType($authoritySlug: String!, $businessTypeSlug: String!, $rating: String, $cursor: String, $limit: Int!) {
		establishments(input: {authoritySlug: $authoritySlug, businessTypeSlug: $businessTypeSlug, rating: $rating, cursor: $cursor, limit: $limit}) {
			establishments {
				id
				fhrsId
				businessName
				addressLine1
				addressLine2
				addressLine3
				addressLine4
				postcode
				businessType {
					businessTypeName
				}
				authority {
					name
				}
				ratingValue
				totalScore
				slug
				schemeType
			}
			nextCursor
			size
		}
	}
`;