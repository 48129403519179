import { gql } from "@apollo/react-hooks";

export const GET_ESTABLISHMENT_BY_ID_QUERY = gql`
	query GetEstablishmentById($id: Int!) {
		establishmentById(input: {id: $id}) {
			id
			fhrsId
			businessName
			addressLine1
			addressLine2
			addressLine3
			addressLine4
			postcode
			businessType {
				businessTypeName
			}
			authority {
				name
				localAuthorityIdCode
				url
				email
				region {
					name
				}
			}
			latitude
			longitude
			slug
			schemeType
			ratingValue
			ratingDate
			scores {
				hygiene {
					scoreValue
				  	scoreDescriptor
				}
				structural {
				 	 scoreValue
				  	scoreDescriptor
				}
				confidenceInManagement {
				  	scoreValue
				  	scoreDescriptor
				}
			}
			newRatingPending
			totalScore
			lastUpdated
		}
	}	
`;