import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { ApolloClient } from "@apollo/client";
import { cache } from "./cache";
import { HelmetProvider } from "react-helmet-async";
import { initializeAnalytics } from "./util/analytics";

const client = new ApolloClient({
	uri: "https://foodowl.co.uk/graphql",
	cache: cache,
});

const helmetContext = {};

const main = async () => {
	await initializeAnalytics("G-C0C0RJDKFW");

	ReactDOM.hydrate(
		<HelmetProvider context={helmetContext}>
			<BrowserRouter>
				<ApolloProvider client={client}>
					<App />
				</ApolloProvider>
			</BrowserRouter>
		</HelmetProvider>,
		document.getElementById("root")
	);
};

main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
