import React, { useEffect } from "react";

interface Props {
	slotId: string;
}

const AdUnit: React.FC<Props> = ({ slotId }) => {
	useEffect(() => {
		(window.adsbygoogle = window.adsbygoogle || []).push({});
	}, []);

	return (
		<ins
			className="adsbygoogle"
			style={{ display: "block" }}
			data-ad-client="ca-pub-6273228371971581"
			data-ad-slot={slotId}
			data-ad-format="auto"
			data-full-width-responsive="true"
		></ins>
	);
};

export default AdUnit;
