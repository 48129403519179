import React from "react";
import AdUnit from "../ads/ad-unit.component";
import { StatsPageData } from "../types/types";

interface Props {
	data: StatsPageData;
}

const CountryStats: React.FC<Props> = ({ data }) => {
	let bestCountryStatsEWNI = [...data.countryStatsEWNI];
	let worstCountryStatsEWNI = [...data.countryStatsEWNI];

	const countryStatsOrderedByPercentageFiveRated = bestCountryStatsEWNI.sort(
		(a, b) => b.percentageFiveRated - a.percentageFiveRated
	);

	let countryStatsOrderedByPercentageOneZeroRated = worstCountryStatsEWNI.sort(
		(a, b) => b.percentageOneZeroRated - a.percentageOneZeroRated
	);

	const worstPerformingCountryStats =
		countryStatsOrderedByPercentageOneZeroRated[0];

	const bestPerformingCountryStats =
		countryStatsOrderedByPercentageFiveRated[0];

	const totalOneZeroRatedBusinessesWorstCountry =
		worstPerformingCountryStats.totalOneRated +
		worstPerformingCountryStats.totalZeroRated;

	return (
		<>
			<h2>Country Level Statistics</h2>
			<p>
				Here's the latest food hygiene rating statistics for each country within
				the UK.
			</p>
			<ul>
				<li>
					<strong>{worstPerformingCountryStats.countryName}</strong> is
					currently the <strong>worst performing country</strong> out of
					England, Wales and Northern Ireland for food hygiene ratings.{" "}
					<strong>{worstPerformingCountryStats.countryName}</strong> currently
					has the greatest percentage of zero and one-rated food establishments.
					There are a total of{" "}
					<strong>
						{worstPerformingCountryStats.totalEstablishments.toLocaleString()}
					</strong>{" "}
					food-based businesses in{" "}
					<strong>{worstPerformingCountryStats.countryName}</strong> and{" "}
					<strong>
						{totalOneZeroRatedBusinessesWorstCountry.toLocaleString()}
					</strong>{" "}
					of those have a rating of 'zero' or 'one'{" "}
					<strong>
						({worstPerformingCountryStats.percentageOneZeroRated.toFixed(2)}
						%).
					</strong>
				</li>
				<li>
					The <strong>best performing country</strong> out of England, Wales and
					Northern Ireland for food hygiene ratings is{" "}
					<strong>{bestPerformingCountryStats.countryName}.</strong>
				</li>
			</ul>

			<AdUnit slotId={"6917298263"} />

			<p>
				<strong>{bestPerformingCountryStats.countryName}</strong> currently has
				the greatest percentage of five-rated food establishments. There are a
				total of{" "}
				<strong>
					{bestPerformingCountryStats.totalEstablishments.toLocaleString()}
				</strong>{" "}
				food-based businesses in{" "}
				<strong>{bestPerformingCountryStats.countryName}</strong> and{" "}
				<strong>
					{bestPerformingCountryStats.totalFiveRated.toLocaleString()}
				</strong>{" "}
				of those have a rating of 'five'{" "}
				<strong>
					({bestPerformingCountryStats.percentageFiveRated.toFixed(2)}%).
				</strong>
			</p>

			<p>
				Here's a table showing the percentage of 5-rated businesses and the
				percentage of 0 and 1-rated businesses for each country:
			</p>

			<div className="responsive-table">
				<table>
					<tbody>
						<tr>
							<th>
								<strong>Country</strong>
							</th>
							<th>
								<strong>5-Rated Businesses (%)</strong>
							</th>
							<th>
								<strong>0 and 1-Rated Businesses (%)</strong>
							</th>
						</tr>
						{countryStatsOrderedByPercentageFiveRated.map(
							(countryStats, index) => {
								return (
									<tr key={index}>
										<td>{countryStats.countryName}</td>
										<td>
											{countryStats.percentageFiveRated.toFixed(2)}% (
											{countryStats.totalFiveRated.toLocaleString()})
										</td>
										<td>
											{countryStats.percentageOneZeroRated.toFixed(2)}% (
											{(
												countryStats.totalOneRated + countryStats.totalZeroRated
											).toLocaleString()}
											)
										</td>
									</tr>
								);
							}
						)}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default CountryStats;
