import { gql } from "@apollo/react-hooks";

export const COUNTRY_STATS_COMBINED_QUERY = gql`
	query CountryStats {
		countryStatsEWNI: countryStats(input: {
			orderBy: PERCENT_FIVE_RATED
			ascDesc: DESC
			schemeType: FHRS
		}) {
			totalEstablishments
			totalZeroRated
		}
		countryStatsScotland: countryStats(input: {
			orderBy: PERCENT_PASS_RATED
			ascDesc: DESC
			schemeType: FHIS
		}) {
			totalEstablishments
			totalImprovementRequired
		}
	}	
`;