import { useQuery } from "@apollo/client";
import React from "react";
import { match } from "react-router-dom";
import AuthorityComponent from "../../components/authority/authority.component";
import { AUTHORITY_LIST_QUERY } from "../../gql/authority.query";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import {
	AuthorityByRegionSlugInput,
	AuthorityData,
	Region,
} from "../../components/types/types";
import Head from "../../components/head/head.component";

interface Params {
	regionSlug: string;
}

interface Props {
	match?: match<Params>;
}

const ArticlesAuthorityPage: React.FC<Props> = ({ match }) => {
	const searchParams: Params = match ? match.params : { regionSlug: "" };

	const { loading, data, error } = useQuery<
		AuthorityData,
		AuthorityByRegionSlugInput
	>(AUTHORITY_LIST_QUERY, {
		variables: {
			regionSlug: searchParams.regionSlug,
			limit: 123,
		},
	});

	if (loading) {
		return <Loading fullscreen={false} />;
	}
	if (error || !data) return <NoData />;

	const region: Region = data.authorityList.authorities[0].region;

	const title = `Local Authorities for ${region.name}`;

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>
				<p>
					Here are the local authorities within the{" "}
					<span className="text-bold">{region.name}</span> region. Select a
					local authority to view news articles for that area:
				</p>
				<AuthorityComponent authorityData={data} />
			</div>
		</div>
	);
};

export default ArticlesAuthorityPage;
