import React from "react";
import { match } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
	EstablishmentDetail,
	EstablishmentByIdInput,
	EstablishmentDetailData,
} from "../../components/types/types";
import { GET_ESTABLISHMENT_BY_ID_QUERY } from "../../gql/establishment-by-id.query";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import EstablishmentDetailComponent from "../../components/establishment/establishment-detail.component";

interface DetailParams {
	id: string;
}

interface Props {
	match?: match<DetailParams>;
}

const EstablishmentDetailPage: React.FC<Props> = ({ match }) => {
	const { loading, data, error } = useQuery<
		EstablishmentDetailData,
		EstablishmentByIdInput
	>(GET_ESTABLISHMENT_BY_ID_QUERY, {
		variables: {
			id: Number(match?.params.id),
		},
	});

	if (loading) return <Loading fullscreen={true} />;
	if (error || !data || !data.establishmentById) return <NoData />;

	const establishment: EstablishmentDetail = data.establishmentById;

	return <EstablishmentDetailComponent establishment={establishment} />;
};

export default EstablishmentDetailPage;
