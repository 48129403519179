import React from "react";
import Head from "../../components/head/head.component";

const AdvertisePage: React.FC = () => {
	const title = "Advertise Your Business On Food Owl";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					Every month <span className="text-bold">thousands</span> of people
					come to Food Owl to check out the hygiene ratings of businesses across
					the UK.
				</p>

				<p>
					So, if you want to get{" "}
					<span className="text-bold">your business</span> in front of these
					people then don't hesitate to get in touch.
				</p>

				<p>
					<a href="mailto:foodowlapp@gmail.com">Click here</a>&nbsp;to send me
					an email.
				</p>

				<p>
					I'd love to hear from you.
					<span role="img" aria-label="smiley">
						&nbsp;🙂
					</span>
				</p>
			</div>
		</div>
	);
};

export default AdvertisePage;
