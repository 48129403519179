import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/head/head.component";

const PrivacyPage: React.FC = () => {
	const title = "Privacy Policy";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>
				<p>
					At Food Owl, we are committed to protecting your personal information
					and take your privacy seriously. This Privacy Policy explains the
					methods we use to collect, utilize, and safeguard your personal data
					when you use our website (hereafter known as "the Website").&nbsp;
				</p>
				<p>
					By accessing and using the Food Owl website, you are agreeing to the
					terms outlined in this policy, as well as our{" "}
					<Link to={"/terms-of-use"}>terms of use.</Link> If you have any
					questions or concerns about our Privacy Policy, please do not hesitate
					to reach out to us for clarification.
				</p>
				<h2>Information Collection and Use</h2>
				<p>
					We collect information from users in a variety of ways, including
					through the use of Google Analytics. Google Analytics is a web
					analytics service offered by Google that tracks and reports website
					traffic.&nbsp;
				</p>
				<p>
					We use this information to understand how visitors use our website,
					such as which pages are most popular and how visitors navigate through
					the site. The information collected by Google Analytics is anonymous
					and does not personally identify individual users.
				</p>
				<p>
					When you use the Website, we may also collect certain information
					about you, such as your IP address, browser type, operating system,
					and access times.&nbsp;
				</p>
				<p>
					We also collect information about your use of the Website, such as the
					pages you visit and the actions you take. This information is used to
					improve the functionality and user experience of the Website.
				</p>
				<p>
					We also collect personal information that you voluntarily provide to
					us when you contact us via email or through the Website. This may
					include your name, email address, and any other information you choose
					to provide. We use this information to respond to your inquiries and
					to provide you with the information or services that you request.
				</p>
				<p>
					We may also use your personal information for marketing or promotional
					purposes, such as to send you emails about new features or services,
					or to inform you about special promotions or discounts. However, we
					will not use your personal information for these purposes without your
					consent.
				</p>
				<h2>Data Retention</h2>
				<p>
					We retain your personal data only for as long as necessary to provide
					you with our services and as described in this Privacy Policy. We
					review and update our retention policies on a regular basis to ensure
					that we are only keeping the data that is necessary for the purpose it
					was collected.&nbsp;
				</p>
				<p>
					We may retain your personal data for a longer period if it is
					necessary to comply with legal or regulatory obligations, to resolve
					disputes, or to enforce our agreements.&nbsp;
				</p>
				<p>
					Once the retention period has expired, we will securely destroy or
					delete your personal data in accordance with our data destruction
					policies.
				</p>
				<h2>Security</h2>
				<p>
					At Food Owl, we take the security of your personal data very
					seriously. We have implemented robust technical and organisational
					measures to protect your personal data against accidental or unlawful
					destruction, loss, alteration, unauthorised disclosure, unauthorised
					access, and any other illegal forms of processing.&nbsp;
				</p>
				<p>
					Our security measures include, but are not limited to, encryption of
					sensitive data, regular backups, access controls and audits,
					vulnerability management, and incident response planning.&nbsp;
				</p>
				<p>
					We also have strict policies and procedures in place to ensure that
					only authorised personnel have access to your personal data.&nbsp; Our
					security protocols are regularly reviewed, tested, and updated to
					ensure that they remain up-to-date with the latest security standards.
				</p>
				<h2>Sharing Information</h2>
				<p>
					We do not sell, distribute, or rent your personal data to any third
					parties without your expressed permission. We may only share your
					personal data with third parties in the event that we have your
					permission to do so, or if we are required by law to do so.&nbsp;
				</p>
				<p>
					In such cases, we will only share the minimum amount of personal data
					necessary to fulfill the specific purpose for which it is being
					shared.&nbsp;
				</p>
				<h2>Cookies</h2>
				<p>
					Our website uses cookies to enhance your browsing experience and to
					personalise content and ads. A cookie is a small text file that is
					placed on your computer or mobile device when you visit our website.
					Cookies are widely used in order to make websites work, or work more
					efficiently, as well as to provide information to the owners of the
					site.
				</p>
				<p>We use the following types of cookies on our website:</p>
				<ul>
					<li>
						Session cookies: These cookies are temporary and are deleted when
						you close your browser. They are used to keep track of your
						preferences and settings as you navigate our website.
					</li>
					<li>
						Persistent cookies: These cookies remain on your device until they
						expire or you delete them. They are used to remember your
						preferences and settings for your next visit to our website.
					</li>
					<li>
						Third-party cookies: These cookies are set by third-party services,
						such as Google Analytics, that we use to enhance the functionality
						of our website.
					</li>
				</ul>
				<h3>Google Analytics</h3>
				<p>
					Our website uses Google Analytics, a web analytics service provided by
					Google, Inc. (“Google”). Google Analytics uses cookies to help us to
					analyse how users use our website.&nbsp;
				</p>
				<p>
					The information generated by the cookie about your use of our website
					(including your IP address) will be transmitted to and stored by
					Google on servers in the United States.&nbsp;
				</p>
				<p>
					Google will use this information for the purpose of evaluating your
					use of our website, compiling reports on website activity for website
					operators and providing other services relating to website activity
					and Internet usage.&nbsp;
				</p>
				<p>
					Google may also transfer this information to third parties where
					required to do so by law, or where such third parties process the
					information on Google's behalf.&nbsp;
				</p>
				<p>
					By using our website, you consent to the processing of data about you
					by Google in the manner and for the purposes set out above.
				</p>
				<p>
					You can control the use of cookies on our website by adjusting the
					settings in your browser. However, disabling cookies may affect your
					ability to use certain features of our website.
				</p>
				<h3>Advertising</h3>
				<p>
					We work with Ezoic, an advertising network provider, to deliver
					advertisements to our users. These advertisements may be personalised
					to provide a more relevant experience for our users. The{" "}
					<a
						href="https://g.ezoic.net/privacy/foodowl.co.uk"
						target="_blank"
						rel="noopener noreferrer"
					>
						privacy policy for Ezoic can be found here
					</a>
					. This page also contains a full list of cookies used on this website.
				</p>
				<h2>Links to other websites</h2>
				<p>
					Our website may contain links to other websites that may be of
					interest to you. However, please note that once you have used these
					links to leave our site, we do not have any control over the other
					websites.&nbsp;
				</p>
				<p>
					Therefore, we cannot be responsible for the protection and privacy of
					any information which you provide whilst visiting such sites, and such
					sites are not governed by this privacy policy.&nbsp;
				</p>
				<p>
					We encourage you to be aware when you leave our website and to read
					the privacy policies of every website that you visit. We do not
					endorse, support or take responsibility for the content of these
					external sites, their privacy policies or the way in which they handle
					user data.
				</p>
				<h2>Information Rights</h2>
				<p>
					In accordance with the UK General Data Protection Regulation (GDPR),
					you have the following rights with respect to your personal
					information that we collect and process:
				</p>
				<h3>The right to be informed</h3>
				<p>
					You have the right to be informed about the collection and use of your
					personal information. This Privacy Policy is intended to provide you
					with clear and transparent information about how we use your personal
					information.
				</p>
				<h3>The right of access</h3>
				<p>
					You have the right to access the personal information we hold about
					you.
				</p>
				<h3>The right to rectification</h3>
				<p>
					You have the right to have any inaccurate personal information we hold
					about you corrected.
				</p>
				<h3>The right to erasure</h3>
				<p>
					You have the right to have your personal information erased in certain
					circumstances.
				</p>
				<h3>The right to restrict processing</h3>
				<p>
					You have the right to request that we restrict the processing of your
					personal information in certain circumstances.
				</p>
				<h3>The right to data portability</h3>
				<p>
					You have the right to receive a copy of your personal information in a
					commonly used electronic format, and the right to transmit that
					information to another data controller.
				</p>
				<h3>The right to object</h3>
				<p>
					You have the right to object to the processing of your personal
					information in certain circumstances, including processing for direct
					marketing purposes.
				</p>
				<h3>Rights in relation to automated decision making and profiling</h3>
				<p>
					You have the right to not be subject to a decision based solely on
					automated processing, including profiling.
				</p>
				<p>
					Please note that in order to exercise these rights, you may be
					required to provide us with proof of identity to ensure that personal
					information is not disclosed to unauthorised third parties.
				</p>
				<p>
					If you wish to exercise any of your rights, please contact us at the
					contact details provided in the Contact Us section of this Privacy
					Policy. We will respond to your request within a reasonable period of
					time and may request additional information to confirm your
					identity.&nbsp;
				</p>
				<h2>Changes to this Privacy Policy</h2>
				<p>
					We may update this Privacy Policy from time to time to reflect changes
					to our privacy practices, or to comply with changes in the law.
				</p>
				<p>
					We encourage you to review this Privacy Policy periodically to stay
					informed about how we are protecting the personal data we
					collect.&nbsp; It is your responsibility to check this Privacy Policy
					periodically to ensure that you are aware of any changes.&nbsp;
				</p>
				<p>
					By continuing to use our website or services after any changes have
					been made, you are agreeing to be bound by the new terms of the
					Privacy Policy.
				</p>
				<h2>Contact Us</h2>
				<p>
					If you have any questions or concerns about our Privacy Policy or the
					way in which we handle your personal data, please contact us.&nbsp;
					You can reach us by email at{" "}
					<a href="mailto:foodowlapp@gmail.com">foodowlapp@gmail.com</a>.&nbsp;
					We will do our best to address your concerns in a timely and efficient
					manner.
				</p>
			</div>
		</div>
	);
};

export default PrivacyPage;
