import React from "react";
import FeaturePanel from "../../components/feature/feature-panel.component";
import HomeSearchPanel from "../../components/home-search-panel/home-search-panel.component";
import RegionPanel from "../../components/region/region-panel.component";

const HomePage: React.FC = () => (
	<>
		<HomeSearchPanel />
		<FeaturePanel />
		<RegionPanel />
	</>
);

export default HomePage;
