import React from "react";
import { Link } from "react-router-dom";
import { EstablishmentDetail, Scores } from "../types/types";
import "./rating-scores.component.scss";

interface Props {
	establishment: EstablishmentDetail;
}

const RatingScores: React.FC<Props> = ({ establishment }) => {
	const scores: Scores = establishment.scores;

	const scoresAvailable = (establishment: EstablishmentDetail) => {
		return establishment.schemeType.toLowerCase() === "fhrs" &&
			scores &&
			scores.hygiene.scoreValue !== null &&
			scores.structural.scoreValue !== null &&
			scores.confidenceInManagement.scoreValue !== null
			? true
			: false;
	};

	const ratingScores = (
		<>
			{scoresAvailable(establishment) ? (
				<div className="establishment-detail-section detail-scores">
					<h2>Rating scores awarded at last inspection:</h2>
					<p>
						The overall rating for a business is determined by adding up the
						scores from the inspection in each of the three areas: hygiene,
						structural, and confidence in management. The fewer points a
						business receives in each area, the better their overall score will
						be.
					</p>
					<p>
						For more detailed information,
						<Link to={"/blog/ratings-explained"}>
							{" "}
							find out how ratings are calculated here.
						</Link>
					</p>

					<div className="detail-scores__area">
						<div className="detail-scores__area__heading">
							Food hygiene and safety:
						</div>
						<div className="detail-scores__area__score">
							<strong>{scores.hygiene.scoreDescriptor}</strong> (Score:{" "}
							{scores.hygiene.scoreValue})
						</div>
					</div>

					<div className="detail-scores__area">
						<div className="detail-scores__area__heading">Structure:</div>
						<div className="detail-scores__area__score">
							<strong>{scores.structural.scoreDescriptor}</strong> (Score:{" "}
							{scores.structural.scoreValue})
						</div>
					</div>

					<div className="detail-scores__area">
						<div className="detail-scores__area__heading">
							Confidence in Management:
						</div>
						<div className="detail-scores__area__score">
							<strong>{scores.confidenceInManagement.scoreDescriptor}</strong>{" "}
							(Score: {scores.confidenceInManagement.scoreValue})
						</div>
					</div>

					<div className="detail-scores__area">
						<div className="detail-scores__area__heading">
							Total Score (lower is better):
						</div>
						<div className="detail-scores__area__score">
							<strong>{establishment.totalScore}</strong>
						</div>
					</div>
				</div>
			) : null}
		</>
	);

	return ratingScores;
};

export default RatingScores;
