import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link, match } from "react-router-dom";
import Head from "../../components/head/head.component";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import SearchResults from "../../components/search/search-results.component";

import {
	Authority,
	BusinessType,
	EstablishmentByAuthorityBusinessTypeInput,
	EstablishmentsData,
} from "../../components/types/types";
import { GET_ESTABLISHMENTS_BY_BUSINESS_TYPE_QUERY } from "../../gql/establishments-by-business-type.query";

interface Params {
	regionSlug: string;
	authoritySlug: string;
	businessTypeSlug: string;
}

interface Props {
	match?: match<Params>;
}

const AuthorityBusinessesPage: React.FC<Props> = ({ match }) => {
	const searchParams: Params = match
		? match.params
		: { regionSlug: "", authoritySlug: "", businessTypeSlug: "" };

	const { loading, data, error, fetchMore } = useQuery<
		EstablishmentsData,
		EstablishmentByAuthorityBusinessTypeInput
	>(GET_ESTABLISHMENTS_BY_BUSINESS_TYPE_QUERY, {
		variables: {
			authoritySlug: searchParams.authoritySlug,
			businessTypeSlug: searchParams.businessTypeSlug,
			limit: 5,
		},
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-first",
		notifyOnNetworkStatusChange: true,
	});

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	if (loading) return <Loading fullscreen={true} />;
	if (error || !data || data?.establishments.size === 0) return <NoData />;

	const handleLoadMoreButtonClick = async () => {
		setIsLoadingMore(true);
		await fetchMore({
			variables: {
				authoritySlug: searchParams.authoritySlug,
				businessTypeSlug: searchParams.businessTypeSlug,
				limit: 5,
				cursor: data.establishments.nextCursor,
			},
		});
		setIsLoadingMore(false);
	};

	const authority: Authority = data.establishments.establishments[0].authority;
	const businessType: BusinessType =
		data.establishments.establishments[0].businessType;

	const title = `Establishments in ${authority.name}`;

	return (
		<>
			<div className="container panel">
				<div className="article">
					<Head title={title} />
					<h1 className="text-bold">{title}</h1>

					<p>
						Here's a list of businesses (and their ratings) in the{" "}
						<span className="text-bold">{businessType.businessTypeName}</span>{" "}
						category.
					</p>

					<p>
						In England, Wales and Northern Ireland food hygiene ratings are
						provided by the The Food Hygiene Rating Scheme (FHRS). Ratings in
						Scotland are provided by a similar scheme called the Food Hygiene
						Information Scheme (FHIS).
					</p>

					<p>
						You can{" "}
						<Link to={"/blog/ratings-explained"}>
							read more about FHRS and FHIS food ratings here
						</Link>
						.
					</p>

					<p>Click on a establishment below for more information:</p>

					<SearchResults
						isLoadingMore={isLoadingMore}
						establishments={data.establishments.establishments}
						nextCursor={data.establishments.nextCursor}
						handleClick={handleLoadMoreButtonClick}
					/>
				</div>
			</div>
		</>
	);
};

export default AuthorityBusinessesPage;
