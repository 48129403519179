import React from "react";
import { Link } from "react-router-dom";
import "./article-card.component.scss";

interface Props {
	headline: string;
	caption: string;
	articlePath: string;
}

const ArticleCard: React.FC<Props> = ({ headline, caption, articlePath }) => {
	return (
		<div className="article-card">
			<Link to={articlePath}>
				<h2>{headline}</h2>
			</Link>
			<p>{caption}</p>
		</div>
	);
};

export default ArticleCard;
