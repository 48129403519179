import React from "react";
import AdUnit from "../../components/ads/ad-unit.component";
import Head from "../../components/head/head.component";

const FoodHygieneRatingsFaqsPage: React.FC = () => {
	const title = "Food Hygiene Rating FAQs";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					Here's the answers to some common questions people have around Food
					Hygiene Ratings.
				</p>
				<p>
					The information relates to the Food Hygiene Ratings Scheme in England.
					It is also applicable to Wales and Northern Ireland.
				</p>

				<AdUnit slotId={"1090256517"} />

				<p>
					There's also some information about the Food Hygiene Information
					Scheme in Scotland.
				</p>

				<h2>
					<strong>What is the range of food hygiene ratings in the UK?</strong>
				</h2>
				<p>
					In England, Wales and Northern Ireland, businesses receive a rating on
					a scale from 0 to 5. A rating of 5 is the highest rating that a
					business can achieve. This means that their hygiene standards are
					'very good'.
				</p>
				<p>
					A rating of 0 is at the lowest end of the scale. It means that 'urgent
					improvement is required'.
				</p>

				<AdUnit slotId={"8777174846"} />

				<h2>
					<strong>What is the</strong> <strong>minimum</strong>{" "}
					<strong>food hygiene rating?</strong>
				</h2>
				<p>
					The minimum food hygiene rating is zero. To get a zero, a business
					will have failures across all three of the following categories:
				</p>
				<ul>
					<li>
						<strong>Food hygiene and safety procedures</strong> - How
						hygienically a business handles food. This includes the preparation,
						cooking, re-heating, cooling and storage of food.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Structural requirements</strong> - The physical condition of
						the business.
					</li>
				</ul>
				<ul>
					<li>
						<strong>Confidence in management</strong> - How the business keeps
						food safe. This includes the processes, systems and training a
						business has in place.
					</li>
				</ul>
				<p>
					Often, in zero-rated establishments, inspectors may witness
					cross-contamination. There may also be evidence of pest infestations
					and lack of hand-washing.
				</p>
				<p>
					If a business is an imminent risk to public health, officers have the
					authority to close it down. Officers can close down part or even the
					entire business.
				</p>

				<AdUnit slotId={"3148625408"} />

				<h2>
					<strong>How often are food hygiene ratings done?</strong>
				</h2>
				<p>
					Businesses that pose the highest risk may receive an inspection every
					six months. Low risk businesses may only receive an inspection every
					two years.
				</p>
				<p>
					The time between inspections may exceed two years for very low risk
					businesses.
				</p>
				<p>
					<strong>
						The frequency of inspections varies depending on the potential risk
						to public health.
					</strong>
				</p>
				<p>
					Officers can inspect a business at any time and they are not
					announced.
				</p>
				<p>
					This is especially the case if officers sense that hygiene standards
					have deteriorated.
				</p>

				<AdUnit slotId={"3938128740"} />

				<h2>
					<strong>Do you have to display food hygiene ratings?</strong>
				</h2>
				<p>
					In England, it is not a legal requirement to display food hygiene
					ratings.
				</p>
				<p>But, it is a legal requirement in Wales and Northern Ireland.</p>
				<p>
					It became mandatory to display the rating in Wales in November 2013.
					In Northern Ireland, it became mandatory in October 2016.
				</p>
				<p>
					In Wales and Northern Ireland, businesses must display their rating
					somewhere prominent. Examples include:
				</p>
				<ul>
					<li>Front door</li>
				</ul>
				<ul>
					<li>Entrance</li>
				</ul>
				<ul>
					<li>Window of the premises</li>
				</ul>
				<p>
					If a business in England doesn't display their rating, you can still
					find their rating.
				</p>
				<p>
					You can do so either on this website or on the Food Standards Agency
					website.
				</p>

				<AdUnit slotId={"9522462061"} />

				<h2>
					<strong>
						Is it mandatory to display food hygiene ratings in Scotland?
					</strong>
				</h2>
				<p>
					No, it is not mandatory and some food outlets may choose not to
					display their rating on their premises.
				</p>
				<p>
					But, you can find out a business' rating on the Food Standards
					Scotland website and on this website.
				</p>

				<AdUnit slotId={"6151011504"} />

				<h2>
					<strong>
						What premises are exempt from the food hygiene rating scheme?
					</strong>
				</h2>
				<p>
					Businesses that don't supply food directly to consumers are 'exempt'.
					Other 'low risk' businesses are also 'exempt' from the scheme.
				</p>
				<p>
					These 'low risk' businesses must only sell pre-packed, low-risk food.
				</p>
				<p>
					Low risk food include biscuits, health foods, confectionery and hot
					drinks etc.
				</p>
				<p>Low risk businesses include:</p>
				<ul>
					<li>Newsagents</li>
				</ul>
				<ul>
					<li>Visitor centres</li>
				</ul>
				<ul>
					<li>Chemist shops</li>
				</ul>
				<ul>
					<li>Leisure centres</li>
				</ul>
				<ul>
					<li>Childminders</li>
				</ul>
				<ul>
					<li>Businesses offer caring services at home</li>
				</ul>
				<ul>
					<li>Village halls</li>
				</ul>
				<p>
					Businesses that are 'exempt' don't receive a hygiene rating. But they
					do receive a visit by the local authority.
				</p>
				<p>
					The Food Standards Agency website lists the name of the business. But
					there will also be an indicator to show that the business is exempt.
				</p>

				<AdUnit slotId={"3636528807"} />

				<h2>
					<strong>Do you have to pay for a food hygiene rating?</strong>
				</h2>
				<p>
					Food businesses need to register with their local authority. Then once
					registered, they may receive an inspection. There is no cost for this.
				</p>
				<p>
					Some local authorities in England charge a fee for a re-visit
					inspection.
				</p>
				<p>
					In Wales and Northern Ireland, all local authorities charge a fee for
					a re-visit.
				</p>

				<AdUnit slotId={"7187926854"} />

				<h2>
					<strong>Can a business improve their hygiene rating?</strong>
				</h2>
				<p>
					Businesses receive a new rating after each inspection by the local
					authority.
				</p>
				<p>
					If hygiene standards have improved, the business may then receive an
					improved rating.
				</p>
				<p>
					The frequency of inspections depends on the potential risk to public
					health.
				</p>
				<p>Businesses can also request a re-visit inspection.</p>

				<AdUnit slotId={"1010365464"} />

				<h2>
					<strong>Can a business make an appeal about their rating?</strong>
				</h2>
				<p>
					Yes, businesses can appeal in writing to their local authority. They
					can do so by filling out a form and sending it to the local authority.
				</p>
				<p>The form is downloadable from the Food Standards Agency website.</p>
				<p>
					But, businesses should contact their local authority officer in the
					first instance. This will help them understand why they received a
					particular hygiene rating.
				</p>
				<h2>
					<strong>Sources</strong>
				</h2>
				<p>
					<a
						href="https://www.food.gov.uk/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Food Standards Agency
					</a>
				</p>
				<p>
					<a
						href="https://www.foodstandards.gov.scot/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Food Standards Scotland
					</a>
				</p>

				<AdUnit slotId={"9997104785"} />
			</div>
		</div>
	);
};

export default FoodHygieneRatingsFaqsPage;
