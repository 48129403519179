import { gql } from "@apollo/react-hooks";

export const BUSINESS_TYPE_LIST_QUERY = gql`
	query GetBusinessTypes {
		businessTypes {
			id
			businessTypeName
			businessTypeId
			slug
		}
	}
`;