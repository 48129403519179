import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonLink from "../button/button-link.component";
import SubmitButton from "../button/submit-button.component";
import TextField from "../input/textfield.component";
import { SearchResult } from "../types/types";
import "./home-search-panel.component.scss";

const HomeSearchPanel: React.FC = () => {
	const history = useHistory();

	const [searchResult, setSearchResult] = useState<SearchResult>({
		name: "",
		location: "",
	});

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		history.push({
			pathname: "/search-results",
			search:
				"?name=" + searchResult.name + "&location=" + searchResult.location,
		});
	};

	return (
		<section className="home-panel">
			<div className="container grid">
				<div className="home-panel__text">
					<h1>Hygiene ratings for restaurants and takeways</h1>
					<p>
						Be wise and find out the cleanest restaurants and takeways in&nbsp;
						<span className="text-bold">your</span> area, before you eat!
					</p>
					<ButtonLink
						text={"Read More"}
						styles={"btn--outline"}
						link={"/about"}
					/>
				</div>

				<div className="home-panel__form floating-card">
					<h4>Check before you eat!</h4>

					<form onSubmit={handleSubmit}>
						<TextField
							name={"name"}
							placeholder={"Enter business name..."}
							requiredField={true}
							handleChange={(e) =>
								setSearchResult({ ...searchResult, name: e.target.value })
							}
						/>

						<TextField
							name={"location"}
							placeholder={"Enter location..."}
							requiredField={true}
							handleChange={(e) =>
								setSearchResult({ ...searchResult, location: e.target.value })
							}
						/>

						<SubmitButton value={"Check Ratings!"} styles={"btn--primary"} />
					</form>
				</div>
			</div>
		</section>
	);
};

export default HomeSearchPanel;
