import React from "react";
import AdUnit from "../ads/ad-unit.component";
import { StatsPageData } from "../types/types";

interface Props {
	data: StatsPageData;
}

const RegionStats: React.FC<Props> = ({ data }) => {
	const worstPerformingRegion = data.bottomRegionStats.find(
		(regionStats) => regionStats.regionSlug.toLowerCase() !== "scotland"
	);

	const bestPerformingRegion = data.topRegionStats.find(
		(regionStats) => regionStats.regionSlug.toLowerCase() !== "scotland"
	);

	const totalOneZeroRatedBusinessesWorstRegion =
		worstPerformingRegion?.totalOneRated
			? +worstPerformingRegion?.totalZeroRated
			: 0;

	return (
		<>
			<h2>Regional Level Statistics</h2>
			<p>
				Next we dive into the latest food hygiene rating statistics for each
				region in the UK.&nbsp; These statistics offer a high-level view of the
				state of food hygiene in each region.
			</p>

			<p className="text-italic">
				<strong>Please note</strong> it's worth noting that the Food Standards
				Agency considers Wales, Northern Ireland, and Scotland to be both
				countries and regions. This is why you will see them listed in both the
				country and region sections of this article.
			</p>

			<ul>
				<li>
					<strong>{worstPerformingRegion?.regionName}</strong> is currently the{" "}
					<strong>worst performing region</strong> out of England, Wales and
					Northern Ireland for food hygiene ratings.{" "}
					<strong>{worstPerformingRegion?.regionName}</strong> currently has the
					greatest percentage of zero and one-rated food establishments. There
					are a total of{" "}
					<strong>
						{worstPerformingRegion?.totalEstablishments.toLocaleString()}
					</strong>{" "}
					food-based businesses in{" "}
					<strong>{worstPerformingRegion?.regionName}</strong> and{" "}
					<strong>
						{totalOneZeroRatedBusinessesWorstRegion.toLocaleString()}
					</strong>{" "}
					of those are rated 'zero' or 'one'{" "}
					<strong>
						({worstPerformingRegion?.percentageOneZeroRated.toFixed(2)}%).
					</strong>
					&nbsp;
				</li>
				<li>
					The <strong>best performing region</strong> out of England, Wales and
					Northern Ireland for food hygiene ratings is{" "}
					<strong>{bestPerformingRegion?.regionName}.</strong>
				</li>
			</ul>

			<AdUnit slotId={"5604216593"} />

			<p>
				<strong>{bestPerformingRegion?.regionName}</strong> currently has the
				greatest percentage of five-rated food establishments. There are a total
				of{" "}
				<strong>
					{bestPerformingRegion?.totalEstablishments.toLocaleString()}
				</strong>{" "}
				food-based businesses in{" "}
				<strong>{bestPerformingRegion?.regionName}</strong> and{" "}
				<strong>{bestPerformingRegion?.totalFiveRated.toLocaleString()}</strong>{" "}
				of those have a rating of 'five'{" "}
				<strong>
					({bestPerformingRegion?.percentageFiveRated.toFixed(2)}%).
				</strong>
			</p>

			<p>
				Here's a table showing the percentage of 5-rated businesses and the
				percentage of 0 and 1-rated businesses for each region in England, Wales
				and Northern Ireland::
			</p>

			<div className="responsive-table">
				<table>
					<tbody>
						<tr>
							<th>
								<strong>Region</strong>
							</th>
							<th>
								<strong>5-Rated Businesses (%)</strong>
							</th>
							<th>
								<strong>0 and 1-Rated Businesses (%)</strong>
							</th>
						</tr>
						{data.topRegionStats
							.filter((stats) => stats.regionSlug !== "scotland")
							.map((regionStats, index) => {
								return (
									<tr key={index}>
										<td>{regionStats.regionName}</td>
										<td>
											{regionStats.percentageFiveRated.toFixed(2)}% (
											{regionStats.totalFiveRated.toLocaleString()})
										</td>
										<td>
											{regionStats.percentageOneZeroRated.toFixed(2)}% (
											{(
												regionStats.totalOneRated + regionStats.totalZeroRated
											).toLocaleString()}
											)
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default RegionStats;
