import React from "react";
import Head from "../../components/head/head.component";
import { STATS_PAGE_QUERY } from "../../gql/stats-page.query";
import { useQuery } from "@apollo/client";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import { StatsPageData } from "../../components/types/types";
import {
	getCurrentYear,
	getCurrentMonth,
} from "../../components/util/date-time.utils";
import HighLevelStats from "../../components/stats/high-level-stats.component";
import CountryStats from "../../components/stats/country-stats.component";
import RegionStats from "../../components/stats/region-stats.component";
import AuthorityStats from "../../components/stats/authority-stats.component";
import { Link } from "react-router-dom";
import AdUnit from "../../components/ads/ad-unit.component";

const FoodHygieneRatingsStatsPage: React.FC = () => {
	const { data, error, loading } = useQuery<StatsPageData>(STATS_PAGE_QUERY, {
		fetchPolicy: "no-cache",
		nextFetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
	});

	if (loading) return <Loading fullscreen={true} />;
	if (error || !data) return <NoData />;

	const title =
		"Rating the Hygiene Standards of UK Food Businesses: The Latest Statistics";

	return (
		<div className="container">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>Food hygiene is an important issue in the UK.</p>
				<p>
					Poor food hygiene can lead to the spread of foodborne illness, which
					can cause serious health problems.
				</p>

				<AdUnit slotId={"6454263834"} />

				<p>
					In fact, a recent scientific study by the Food Standards Agency (FSA)
					estimates that around{" "}
					<strong>
						2.4 million cases of foodborne illness occur every year
					</strong>{" "}
					in the UK (
					<a
						href="https://www.food.gov.uk/news-alerts/news/fsa-research-suggests-new-higher-estimates-for-the-role-of-food-in-uk-illness"
						target="_blank"
						rel="noopener noreferrer"
					>
						source
					</a>
					).
				</p>
				<p>
					This represents a significant burden on both public health and the
					economy and costs the UK around <strong>£9.1 billion per year</strong>{" "}
					(
					<a
						href="https://www.food.gov.uk/research/foodborne-disease/the-burden-of-foodborne-disease-in-the-uk-2018-0"
						target="_blank"
						rel="noopener noreferrer"
					>
						source
					</a>
					).
				</p>
				<p>
					To help address this problem, the UK has implemented the{" "}
					<strong>
						<Link to="/blog/ratings-explained">Food Hygiene Rating Scheme</Link>
					</strong>
					, which is managed by the FSA and local authorities.&nbsp;
				</p>
				<p>
					The scheme aims to inform consumers about the hygiene standards of
					food businesses and encourage those businesses to improve their
					practices.&nbsp;
				</p>

				<AdUnit slotId={"1097177138"} />
				<p>
					Under the scheme, food businesses are inspected by local authorities
					and assigned a rating based on their compliance with food safety
					regulations.&nbsp;
				</p>
				<p>
					These ratings are often displayed prominently at the business's
					premises, allowing consumers to make informed choices about where to
					eat or purchase food.&nbsp; They are also available here on{" "}
					<Link to="/">Food Owl</Link>
					and the{" "}
					<a
						href="https://ratings.food.gov.uk/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Food Standards Agency website.
					</a>
				</p>

				<p>
					While these ratings are helpful in evaluating the performance of
					individual businesses, I thought it would be interesting to look at
					the latest food hygiene ratings statistics for local and regional
					areas in the UK. Before we do, here are some overall statistics for
					the UK:
				</p>

				<p className="text-italic">
					<strong>Please note</strong> that the data in this article was
					obtained from the Food Standards Agency website and is updated on a
					nightly basis. This ensures that the information is as accurate and
					up-to-date as possible.
				</p>

				<AdUnit slotId={"5412644904"} />

				<HighLevelStats data={data} />

				<p>
					Those are some interesting numbers, but let's delve into some more
					interesting statistics and look at where the best and the worst
					regions are for food hygiene ratings in the UK.
				</p>

				<AdUnit slotId={"5141182163"} />

				<h2>
					Eye-Opening Food Hygiene Statistics for{" "}
					{getCurrentMonth() + " " + getCurrentYear()}{" "}
				</h2>
				<p>
					These statistics provide a snapshot of the state of food hygiene in
					the UK today.&nbsp; They highlight both the successes and the
					challenges that the country faces in ensuring that the food we eat is
					free from contamination and is safe to consume.
				</p>

				<CountryStats data={data} />

				<AdUnit slotId={"2786481568"} />

				<RegionStats data={data} />

				<AdUnit slotId={"4844850456"} />

				<AuthorityStats data={data} />

				<AdUnit slotId={"4936349794"} />

				<h2>Wrapping Up</h2>
				<p>
					Overall, the UK's Food Standards Agency has a strong food hygiene
					rating scheme in place to protect the public's health.&nbsp; However,
					as these statistics show, there is still room for improvement in
					certain areas of the country.&nbsp;
				</p>
				<p>
					It is important for both consumers and food businesses to be aware of
					these ratings and for businesses in particular to take steps to ensure
					that food is safe for consumption.
				</p>
				<p>
					Whether through careful food handling practices or by choosing to eat
					only at establishments with high ratings, we can all play a role in
					improving food hygiene in the UK.&nbsp;
				</p>
				<p>
					This helps to protect public health by reducing the spread of
					foodborne illness which, otherwise, can cause serious health problems
					and increase pressure on the NHS and the UK economy.
				</p>

				<AdUnit slotId={"1758687962"} />
			</div>
		</div>
	);
};

export default FoodHygieneRatingsStatsPage;
