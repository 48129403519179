import React from "react";
import { useQuery } from "@apollo/client";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import Head from "../../components/head/head.component";
import {
	CountryStatsData,
	CountryStatsInput,
} from "../../components/types/types";
import { COUNTRY_STATS_QUERY } from "../../gql/country-stats.query";
import { percentage } from "../../util/math";
import AdUnit from "../../components/ads/ad-unit.component";

const HygieneRating1MeaningPage: React.FC = () => {
	const { data, error, loading } = useQuery<
		CountryStatsData,
		CountryStatsInput
	>(COUNTRY_STATS_QUERY, {
		fetchPolicy: "no-cache",
		nextFetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
	});

	if (loading) return <Loading fullscreen={true} />;

	if (error || !data) return <NoData />;

	const totalEstablishmentsEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalEstablishments,
		0
	);
	const totalOneRatedEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalOneRated,
		0
	);
	const percentageOneRatedEWNI = percentage(
		totalOneRatedEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const title = "What Does A 1 Star Hygiene Rating Mean?";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					<strong>
						The official definition is that major improvement is necessary'.
					</strong>
				</p>
				<p>
					Officers add points when there are certain shortcomings found during
					an inspection.&nbsp; The more points an establishment receives, the
					worse their hygiene rating will be.
				</p>

				<AdUnit slotId={"6833322189"} />

				<p>
					Likewise, the fewer points a business receives, the better their
					rating will be.
				</p>
				<p>
					An establishment with a food hygiene rating of 1 will have scored
					between 45 and 50 points.
				</p>

				<h2>
					<strong>
						Is it safe to eat in an establishment with a 1 star rating?
					</strong>
				</h2>
				<p>
					If there is an imminent risk to the public, the Food Standards Agency
					can close down a business on the spot.
				</p>
				<p>
					If the business does not get shut down, there is no imminent risk to
					the public.&nbsp; So, it is safe for you to eat there.
				</p>
				<p>
					But, a business with a hygiene rating of 1 means there were
					shortcomings in one or more of the following areas:
				</p>
				<ul>
					<li>Food Hygiene and Safety</li>
					<li>Structural Compliance</li>
					<li>Confidence in Management</li>
				</ul>

				<AdUnit slotId={"9112819405"} />

				<p>
					But, a rating of 1 doesnt mean the establishment has a bad score in
					all three areas.&nbsp;
				</p>
				<p>
					In fact, an establishment could be very clean and have good hygiene
					standards in place.
				</p>
				<p>
					But they could receive a poor rating due to lack of procedures.&nbsp;
					Also, they might not have the systems in place to ensure food safety.
				</p>
				<p>
					Lets look at an example.&nbsp; Lets say business A with a rating of 1
					scores the following:
				</p>
				<ul>
					<li>'Hygienic food handling' - Generally satisfactory</li>
					<li>
						'Cleanliness and condition of facilities and building' - Generally
						satisfactory
					</li>
					<li>'Management of food safety' - Major improvement necessary</li>
				</ul>

				<AdUnit slotId={"4618222386"} />

				<p>
					Another business (business B) may have the same 'Hygienic food
					handling' score.&nbsp; But they may score better in other areas.&nbsp;
				</p>
				<p>
					Business B would&nbsp; get a rating of 4 with the following scores:
				</p>
				<ul>
					<li>'Hygienic food handling' - Generally satisfactory</li>
					<li>'Cleanliness and condition of facilities and building' - Good</li>
					<li>'Management of food safety' - Good</li>
				</ul>
				<p>
					In other words, looking into the details of the rating may help you
					make a more informed decision.
				</p>

				<AdUnit slotId={"6733249834"} />

				<h2>
					<strong>Reasons why establishments get a 1 star rating</strong>
				</h2>
				<p>
					Establishments could get a 1 star rating for a variety of reasons:
				</p>
				<ul>
					<li>Bad hygiene standards and cleanliness</li>
					<li>Lack of training among staff</li>
					<li>
						Lack of systems and procedures in place to ensure good food hygiene
					</li>
					<li>Bad structural quality of the premises</li>
					<li>Failure to keep good records</li>
					<li>
						Failure to produce paperwork required by the inspection officer
					</li>
					<li>
						Failing to keep equipment and facilities in good working
						order.&nbsp; An example could be a broken hand basin.
					</li>
				</ul>

				<AdUnit slotId={"1992059040"} />

				<h2>
					<strong>
						Do establishments get a 1 star rating due to missing paperwork?
					</strong>
				</h2>
				<p>
					Businesses don't get a 1 star rating due to missing paperwork
					alone.&nbsp; Paperwork and procedures are a consideration.&nbsp; But
					this is not the only consideration.
				</p>
				<p>
					For example, inspectors will look at hygiene practices such as regular
					handwashing.&nbsp; They also look to see they store food at the
					correct temperatures.
				</p>
				<p>Other things inspectors look for include (but not limited to):</p>
				<ul>
					<li>Thorough washing of vegetables, fruit and salads</li>
					<li>Safe defrosting of frozen foods</li>
					<li>Staff knowledge of allergy hazards</li>
					<li>Structure and condition of the premises</li>
					<li>Maintenance and cleanliness of equipment</li>
					<li>Number of sinks</li>
					<li>Cooking temperatures</li>
					<li>Temperature of fridges</li>
					<li>Speed of food refrigeration</li>
				</ul>

				<AdUnit slotId={"4207158841"} />

				<h2>
					<strong>Do establishments with a 1 star rating get shut down?</strong>
				</h2>
				<p>
					Businesses only get shut down if inspectors find an imminent risk to
					health.&nbsp; In other words, if the food is unsafe to eat.
				</p>
				<h2>
					<strong>What happens if you get a 1 hygiene rating?</strong>
				</h2>
				<p>
					In the case of a rating of 1 (major improvement necessary), a business
					will receive guidance on how to improve.
				</p>
				<p>
					But unless there is an imminent risk to public health, the business
					can continue to trade.&nbsp; This is despite the business receiving
					such a low rating.
				</p>

				<p>
					In England, Wales and Northern Ireland, there are{" "}
					<strong>{totalEstablishmentsEWNI.toLocaleString()}</strong> food
					businesses registered with the Food Standards Agency. Out of those:
				</p>

				<ul>
					<li>
						A total of <strong>{totalOneRatedEWNI.toLocaleString()}</strong>{" "}
						businesses have achieved a rating of 1 (
						<strong>{percentageOneRatedEWNI}%</strong>).
					</li>
				</ul>

				<AdUnit slotId={"7052814030"} />
			</div>
		</div>
	);
};

export default HygieneRating1MeaningPage;
