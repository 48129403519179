import GA4React, { useGA4React } from "ga-4-react";
import { GA4ReactResolveInterface } from "ga-4-react/dist/models/gtagModels";

const timeout = 30000; // millisecond.

type AnalyticsEvent = {
	local_authority_pageview: {
		local_authority: string;
	};
	region_pageview: {
		region: string;
	};
	app_version: {
		version: string;
	};
};

export const initializeAnalytics = async (ga4Id: string) => {
	try {
		const ga = new GA4React(ga4Id, undefined, undefined, timeout);
		const googleAnalytics: GA4ReactResolveInterface | void = await ga.initialize();
		googleAnalytics.gtag("event", "app_version", { version: "1.3" });
	} catch (ex) {
		console.error(`Unable to initialize Google Analytics because of ${ex}`);
	}
};

export const useAnalytics = () => {
	const googleAnalytics: GA4ReactResolveInterface | void = useGA4React();

	const trackEvent = <K extends keyof AnalyticsEvent>(name: K, data: AnalyticsEvent[K]): void => {
		if (googleAnalytics) {
			googleAnalytics.gtag("event", name, data);
		}
	};

	const pageview = (location: string): void => {
		if (googleAnalytics) {
			googleAnalytics.pageview(location);
		}
	};

	return { trackEvent, pageview };
};