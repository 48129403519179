import React from "react";
import Head from "../../components/head/head.component";

const ResourcesPage: React.FC = () => {
	const title = "Useful Resources";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					Here's a list of websites you may find useful, particularly if you're
					a food business owner.
				</p>
				<ul>
					<li>
						<a
							href="https://ratings.food.gov.uk/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Food Standards Agency
						</a>
					</li>
					<li>
						<a
							href="https://www.foodstandards.gov.scot/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Food Standards Scotland
						</a>
					</li>
					<li>
						<a
							href="https://www.food.gov.uk/sites/default/files/media/document/food-safety-checklist.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							Food safety checklist
						</a>
					</li>

					<li>
						<a
							href="https://www.food.gov.uk/business-guidance/cleaning-effectively-in-your-business"
							target="_blank"
							rel="noopener noreferrer"
						>
							Food Standards Agency: Effective Cleaning Guide
						</a>
					</li>

					<li>
						<a
							href="https://www.food.gov.uk/business-guidance/safer-food-better-business-sfbb"
							target="_blank"
							rel="noopener noreferrer"
						>
							Safer food better business pack
						</a>
					</li>

					<li>
						<a
							href="https://www.highspeedtraining.co.uk/courses/food-hygiene/level2-food-hygiene-safety-catering/"
							target="_blank"
							rel="noopener noreferrer sponsored"
						>
							Food Safety &amp; Hygiene Level 2 Course For Catering
						</a>
					</li>

					<li>
						<a
							href="https://www.food.gov.uk/business-guidance/hazard-analysis-and-critical-control-point-haccp"
							target="_blank"
							rel="noopener noreferrer"
						>
							Hazard Analysis and Critical Control Point (HACCP)
						</a>
					</li>
					<li>
						<a
							href="https://www.gov.uk/register-renew-waste-carrier-broker-dealer-england"
							target="_blank"
							rel="noopener noreferrer"
						>
							Register or renew as a waste carrier, broker or dealer
						</a>
					</li>
					<li>
						<a
							href="https://www.gov.uk/food-business-registration"
							target="_blank"
							rel="noopener noreferrer"
						>
							Food business registration
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default ResourcesPage;
