import Footer from "./components/footer/footer.component";
import { Main } from "./Main";

import React, { useEffect } from "react";

import "./scss/styles.scss";
import { useLocation } from "react-router";
import { useAnalytics } from "./util/analytics";
import NavBar from "./components/navbar/navbar.component";

const App: React.FC = () => {
	const location = useLocation();
	const { pageview } = useAnalytics();

	useEffect(() => {
		pageview(location.pathname + location.search);
	}, [location, pageview]);

	return (
		<>
			<NavBar />
			<Main />
			<Footer />
		</>
	);
};

export default App;
