import React from "react";
import { Link } from "react-router-dom";
import { getCurrentYear } from "../util/date-time.utils";
import "./footer.component.scss";

const Footer: React.FC = () => {
	return (
		<footer className="footer bg-dark pt-5">
			<div className="container grid">
				<div>
					<h1 className="footer__logo">
						<Link to="/">
							<span className="text-bold">Food</span>Owl
						</Link>
					</h1>
					<p className="text-italic text-small">
						Data provided by the Food Standards Agency under the terms of the{" "}
						<a
							className="text-underline"
							href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Open Government Licence.
						</a>
					</p>
				</div>

				<div>
					<h3>Blog</h3>
					<ul>
						<li>
							<Link to="/blog">Blog Articles</Link>
						</li>
						<li>
							<Link to="/news">News Articles</Link>
						</li>
						<li>
							<Link to="/blog/food-hygiene-rating-statistics">Statistics</Link>
						</li>
					</ul>
				</div>

				<div>
					<h3>Contact</h3>
					<ul>
						<li>
							<Link to="/about">About</Link>
						</li>
						<li>
							<Link to="/advertise">Advertise</Link>
						</li>
						<li>
							<Link to="/privacy-policy">Privacy Policy</Link>
						</li>
						<li>
							<Link to="/terms-of-use">Terms of Use</Link>
						</li>
					</ul>
				</div>
			</div>

			<section className="footer-legal mt-5">
				<div className="container py-3">
					Copyright &copy; {getCurrentYear()} Food Owl
				</div>
			</section>
		</footer>
	);
};

export default Footer;
