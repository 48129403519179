import { useQuery } from "@apollo/client";
import React from "react";
import { Link, match } from "react-router-dom";
import AdUnit from "../../components/ads/ad-unit.component";
import Head from "../../components/head/head.component";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import {
	Authority,
	EstablishmentDetail,
	EstablishmentByAuthorityRatingInput,
	EstablishmentsData,
} from "../../components/types/types";
import { getAddress } from "../../components/util/data.utils";
import { GET_ESTABLISHMENTS_BY_AUTHORITY_RATING_QUERY } from "../../gql/establishments-by-authority-rating";

interface Params {
	authoritySlug: string;
}

interface Props {
	match?: match<Params>;
}

const ZeroRatedPage: React.FC<Props> = ({ match }) => {
	const searchParams: Params = match ? match.params : { authoritySlug: "" };

	const { data, error, loading } = useQuery<
		EstablishmentsData,
		EstablishmentByAuthorityRatingInput
	>(GET_ESTABLISHMENTS_BY_AUTHORITY_RATING_QUERY, {
		variables: {
			authoritySlug: searchParams.authoritySlug,
			rating: "0",
			limit: 1000000000,
		},
		fetchPolicy: "no-cache",
		nextFetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
	});

	if (loading) return <Loading fullscreen={true} />;

	if (error || !data) return <NoData />;

	const numberOfEstablishments = data?.establishments.size;

	if (numberOfEstablishments === 0) {
		return (
			<div className="container panel">
				<div className="article">
					<p>
						There are currently no 'zero' rated establishments in this area.
					</p>
				</div>
			</div>
		);
	}

	const authority: Authority = data.establishments.establishments[0].authority;

	const title = `Revealed: Establishments rated ZERO for Food Hygiene in ${authority.name}`;

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					There are some establishments that have been rated as 'zero' by local
					authority food safety officers under the Food Standards Agency's
					national Food Hygiene Ratings Scheme (FHRS).
				</p>

				{numberOfEstablishments > 1 ? (
					<p>
						In total, there are{" "}
						<span className="text-bold">{numberOfEstablishments} </span> of
						these businesses within the{" "}
						<span className="text-bold">{authority.name} </span> area.
					</p>
				) : (
					<p>
						In <span className="text-bold">{authority.name} </span>
						there is only one business with the lowest possible hygiene rating.
					</p>
				)}

				<AdUnit slotId={"7664840519"} />

				<p>
					A food hygiene rating of 'zero' is at the bottom of the food hygiene
					ratings scale and it means 'urgent improvement' is necessary.
				</p>

				<p>
					Establishments that pose an imminent risk to public health where the
					food is deemed unsafe to eat, the officer has the authority to stop
					part of the business or close it down completely.
				</p>

				<p>
					The establishment would only then be allowed to reopen once standards
					improve and it is safe to recommence.
				</p>

				<p>
					The scale goes from zero to five and those that have a 'five-star'
					rating are judged to be the most hygienic.
				</p>

				<AdUnit slotId={"9827931430"} />

				<p>
					To arrive at an overall rating for the business, food safety officers
					look into areas such as the handling, preparation, cooking and storage
					of food.
				</p>

				<p>
					They also consider the cleanliness of the facilities used to prepare
					food and inspect the condition of the premises. Confidence in
					management and the procedures they use are also checked.
				</p>

				<p>
					A score is given to an establishment for each of these areas and the
					scores are then added together to form an overall score.
				</p>

				<p>
					The higher the score the worse the establishment has performed at the
					time of inspection in terms of food hygiene.
				</p>

				<AdUnit slotId={"6003456243"} />

				<p>
					If the establishment scores 50 or more, it will be given a hygiene
					rating of zero.
				</p>

				<p>
					You can find out{" "}
					<Link to={"/blog/ratings-explained"}>
						how food hygiene ratings are calculated here.
					</Link>
				</p>

				<p>
					Here's a full list of establishments in the {authority.name} borough
					that have been given a zero star rating for hygiene:
				</p>

				<p>
					Our list is based on the data obtained from the Food Standards Agency
					and is regularly updated.
				</p>

				<ul>
					{data.establishments.establishments.map((est) => (
						<li>
							<Link key={est.id} to={getUrl(est.slug, est.fhrsId)}>
								{getUrlAnchorText(est)}
							</Link>{" "}
							- Total Score: {est.totalScore}
							{est.totalScore === "0" ? (
								<span className="text-bold"> (Perfect Hygiene Score)</span>
							) : null}
						</li>
					))}
				</ul>

				<AdUnit slotId={"8514849766"} />
			</div>
		</div>
	);
};

export default ZeroRatedPage;

const getUrl = (
	establishmentSlug: string,
	establishmentFhrsId: string
): string => {
	return `/${establishmentSlug}/${establishmentFhrsId}`;
};

const getUrlAnchorText = (est: EstablishmentDetail): string => {
	const address: string = getAddress(est);
	return est.businessName + (address ? ", " + address : "");
};
