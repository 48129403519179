import { useQuery } from "@apollo/client";
import React from "react";
import * as reactRouterDom from "react-router-dom";
import ArticleCard from "../../components/article-card/article-card.component";
import { AUTHORITY_LIST_QUERY } from "../../gql/authority.query";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import {
	Authority,
	AuthorityByAuthoritySlugInput,
	AuthorityData,
	Region,
} from "../../components/types/types";
import Head from "../../components/head/head.component";

interface Params {
	authoritySlug: string;
}

interface Props {
	match?: reactRouterDom.match<Params>;
}

const NewsListPage: React.FC<Props> = ({ match }) => {
	const searchParams: Params = match ? match.params : { authoritySlug: "" };

	const { loading, data, error } = useQuery<
		AuthorityData,
		AuthorityByAuthoritySlugInput
	>(AUTHORITY_LIST_QUERY, {
		variables: {
			authoritySlug: searchParams.authoritySlug,
			limit: 1000000000,
		},
	});

	if (loading) {
		return <Loading fullscreen={false} />;
	}
	if (error || !data) return <NoData />;

	const authority: Authority = data.authorityList.authorities[0];
	const region: Region = authority.region;

	const title = "Food Hygiene News Articles";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					Here are the latest news articles specific to the{" "}
					<span className="text-bold">{authority.name}</span> local authority in
					the <span className="text-bold">{region.name}</span> region.
				</p>

				<p>
					These articles are updated regularly with the most recent Food
					Standards Agency data.
				</p>

				{region.name.toLowerCase() !== "scotland" ? (
					<ArticleCard
						headline={`The Cleanest Takeaways and Sandwich Shops in ${authority.name}`}
						caption={`Here are the cleanest five-star rated takeaways and sandwich shops
							in the ${authority.name} area.`}
						articlePath={`/news/${region.slug}/${authority.slug}/cleanest-takeaways-sandwich-shops`}
					/>
				) : null}

				{region.name.toLowerCase() !== "scotland" ? (
					<ArticleCard
						headline={`Revealed: Establishments rated ZERO for Food Hygiene in ${authority.name}`}
						caption={`Here's a list of all the establishments rated zero for food hygiene
							in the ${authority.name} area.`}
						articlePath={`/news/${region.slug}/${authority.slug}/zero-food-hygiene-rating`}
					/>
				) : null}
			</div>
		</div>
	);
};

export default NewsListPage;
