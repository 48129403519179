import React from "react";
import { percentage } from "../../util/math";
import { StatsPageData } from "../types/types";
import { getCurrentMonth, getCurrentYear } from "../util/date-time.utils";

interface Props {
	data: StatsPageData;
}

const HighLevelStats: React.FC<Props> = ({ data }) => {
	const totalEstablishmentsEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalEstablishments,
		0
	);

	const totalEstablishmentsScotland = data.countryStatsScotland.reduce(
		(acc, country) => acc + country.totalEstablishments,
		0
	);

	const totalFiveRatedEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalFiveRated,
		0
	);

	const totalFiveRatedZeroScoreEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalFiveRatedZeroScore,
		0
	);

	const totalOneRatedEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalOneRated,
		0
	);

	const totalZeroRatedEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalZeroRated,
		0
	);

	const totalPassRatedScotland = data.countryStatsScotland.reduce(
		(acc, country) => acc + country.totalPass,
		0
	);

	const totalImprovmentRequiredRatedScotland = data.countryStatsScotland.reduce(
		(acc, country) => acc + country.totalImprovementRequired,
		0
	);

	const percentageFiveRatedEWNI = percentage(
		totalFiveRatedEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const percentageFiveRatedZeroScoreEWNI = percentage(
		totalFiveRatedZeroScoreEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const percentageOneRatedEWNI = percentage(
		totalOneRatedEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const percentageZeroRatedEWNI = percentage(
		totalZeroRatedEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const percentagePassRatedScotland = percentage(
		totalPassRatedScotland,
		totalEstablishmentsScotland
	).toFixed(2);

	const percentageImprovementRequiredRatedScotland = percentage(
		totalImprovmentRequiredRatedScotland,
		totalEstablishmentsScotland
	).toFixed(2);

	return (
		<>
			<h2>
				Latest Food Hygiene Rating Statistics (
				{getCurrentMonth() + " " + getCurrentYear()})
			</h2>
			<ul>
				<li>
					Out of <strong>{totalEstablishmentsEWNI.toLocaleString()}</strong>{" "}
					businesses in England, Wales and Northern Ireland,{" "}
					<strong>{totalFiveRatedEWNI.toLocaleString()}</strong> businesses have
					achieved the highest possible rating of 5{" "}
					<strong>({percentageFiveRatedEWNI}%).</strong>
				</li>
				<li>
					A total of{" "}
					<strong>{totalFiveRatedZeroScoreEWNI.toLocaleString()}</strong>{" "}
					businesses in England, Wales and Northern Ireland have achieved a{" "}
					<strong>perfect hygiene score</strong>{" "}
					<strong>({percentageFiveRatedZeroScoreEWNI}%).</strong> A business
					with a perfect hygiene score was awarded a rating of 'five' and zero
					points on their last inspection. This means inspectors could not find
					any shortcomings in terms of food hygiene.
				</li>
				<li>
					There are currently a total of{" "}
					<strong>{totalZeroRatedEWNI.toLocaleString()}</strong> zero-rated
					businesses <strong>({percentageZeroRatedEWNI}%)</strong> and{" "}
					<strong>{totalOneRatedEWNI.toLocaleString()}</strong> one-rated
					businesses <strong>({percentageOneRatedEWNI}%)</strong> in England,
					Wales and Northern Ireland.
				</li>
				<li>
					In Scotland, there are currently{" "}
					<strong>{totalEstablishmentsScotland.toLocaleString()}</strong>{" "}
					food-based businesses. Out of those,{" "}
					<strong>{totalPassRatedScotland.toLocaleString()}</strong> have a
					rating of 'Pass' <strong>({percentagePassRatedScotland}%)</strong> and{" "}
					<strong>
						{totalImprovmentRequiredRatedScotland.toLocaleString()}
					</strong>{" "}
					have a rating of 'Improvement Required'{" "}
					<strong>({percentageImprovementRequiredRatedScotland}%).</strong> The
					remaining businesses are 'excempt'.
				</li>
			</ul>
		</>
	);
};

export default HighLevelStats;
