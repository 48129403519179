import React from "react";
import { EstablishmentDetail } from "../types/types";

import "./last-updated.component.scss";

interface Props {
	establishment: EstablishmentDetail;
}

const LastUpdated: React.FC<Props> = ({ establishment }) => {
	const lastUpdated = (
		<div className="last-updated">
			<div className="last-updated__heading">Last Updated:</div>
			<div className="last-updated__line1">{establishment.lastUpdated}</div>
		</div>
	);

	return lastUpdated;
};

export default LastUpdated;
