export const getCurrentMonth = () => {
	const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	let monthIndex = (new Date().getMonth());
	return monthNames[monthIndex];
};

export const getCurrentShortMonth = () => {
	const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	let monthIndex = (new Date().getMonth());
	return monthNames[monthIndex];
};

export const getCurrentYear = () => {
	return new Date().getFullYear();
};