import React from "react";

import "./ratings-explained.page.scss";

import RatingImage from "../../components/rating-image/rating-image.component";
import Head from "../../components/head/head.component";
import AdUnit from "../../components/ads/ad-unit.component";

const RatingsExplainedPage: React.FC = () => {
	const title = "Food Hygiene Ratings Explained";

	return (
		<div className="container panel ratings-explained">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>
				<p>
					The&nbsp;
					<span className="text-bold">Food Hygiene Rating Scheme (FHRS)</span>
					&nbsp;is a&nbsp;
					<a
						href="https://www.food.gov.uk/safety-hygiene/food-hygiene-rating-scheme"
						target="_blank"
						rel="noopener noreferrer"
					>
						Food Standards Agency scheme&nbsp;
					</a>
					that is run in conduction with local authorities in England, Wales and
					Northern Ireland.
				</p>

				<p>
					Following an inspection by the local authority, businesses are given a
					rating between 0 and 5, with 0 being the lowest rating and 5 being the
					highest:
				</p>

				<AdUnit slotId={"5078205623"} />

				<p>5 – hygiene standards are very good</p>
				<p>4 – hygiene standards are good</p>
				<p>3 – hygiene standards are generally satisfactory</p>
				<p>2 – some improvement is necessary</p>
				<p>1 – major improvement is necessary</p>
				<p>0 – urgent improvement is required</p>

				<p>
					The ratings themselves are only a snapshot in time and don't guarantee
					hygiene standards between inspections, but they do allow consumers to
					make informed decisions when it comes to choosing where to eat and is
					a reflection on how well the business is doing overall.
				</p>

				<p>
					The rating covers the handling of food, how food is stored, how food
					is prepared, the cleanliness of facilities and how food safety is
					managed.
				</p>

				<p>
					It does not however cover the quality of the food, customer service,
					culinary skill, presentation or comfort.
				</p>

				<p>The rating itself is made up of three main components:</p>

				<AdUnit slotId={"3765123957"} />

				<h3 className="text-bold">Food hygiene and safety procedures</h3>

				<p>
					How hygienically the food is handled – how it is prepared, cooked,
					re-heated, cooled and stored.
				</p>

				<p>
					Food hygiene is graded on a scale of 0 to 25 with 0 being the top
					score and 25 the poorest score.
				</p>

				<h3 className="text-bold">Structural requirements</h3>

				<p>
					The physical condition of the business this includes cleanliness,
					layout, lighting, ventilation, pest control and other facilities.
				</p>

				<p>
					Structural requirements are graded on a scale of 0 to 25 with 0 being
					the top score and 25 the poorest score.
				</p>

				<AdUnit slotId={"6723255941"} />

				<h3 className="text-bold">Confidence in management</h3>

				<p>
					Covers how the business keeps food safe and the processes, systems and
					training that the business has in place to ensure that good food
					hygiene standards are maintained now and in the future.
				</p>

				<p>
					Confidence in management is graded on a scale of 0 to 30 with 0 being
					the top score and 30 the poorest score.
				</p>

				<h3 className="text-bold">Total score</h3>

				<p>
					If you add the scores from each rating category, then you will arrive
					at a <span className="text-bold">total score</span> which is then
					combined to get a final overall rating for the business.
				</p>

				<p>
					You will see the total score frequently when you search for businesses
					on this website. It allows you to see the very best five star
					businesses and the poorest zero star businesses:
				</p>

				<AdUnit slotId={"5161147861"} />

				<table className="ratings-table">
					<tbody>
						<tr>
							<th>
								<strong>Total Score</strong>
							</th>
							<th>
								<strong>Rating</strong>
							</th>
							<th>
								<strong>Definition</strong>
							</th>
						</tr>
						<tr>
							<td>0 - 15</td>
							<td>
								<RatingImage rating={"5"} fullSize={true} />
							</td>
							<td>Very Good</td>
						</tr>

						<tr>
							<td>20</td>
							<td>
								<RatingImage rating={"4"} fullSize={true} />
							</td>
							<td>Good</td>
						</tr>

						<tr>
							<td>25 - 30</td>
							<td>
								<RatingImage rating={"3"} fullSize={true} />
							</td>
							<td>Generally satisfactory</td>
						</tr>

						<tr>
							<td>35 - 40</td>
							<td>
								<RatingImage rating={"2"} fullSize={true} />
							</td>
							<td>Improvement necessary</td>
						</tr>

						<tr>
							<td>45 - 50</td>
							<td>
								<RatingImage rating={"1"} fullSize={true} />
							</td>
							<td>Major improvement necessary</td>
						</tr>

						<tr>
							<td>45 - 50</td>
							<td>
								<RatingImage rating={"0"} fullSize={true} />
							</td>
							<td>Urgent improvement necessary</td>
						</tr>
					</tbody>
				</table>

				<h2 className="text-bold">
					Food Hygiene Information Scheme in Scotland (FHIS)
				</h2>

				<p>
					The FHIS scheme is used in Scotland rather than the FHRS scheme. It is
					similar in that it is still run by the Food Standards Agency in
					conjunction with local authorities but the ratings themselves are
					different (and simpler).
				</p>

				<p>
					Following a food hygiene inspection by the local authority, businesses
					are given one of three ratings:
				</p>

				<h3 className="text-bold">Pass</h3>
				<p>
					This rating means that the business meets the legal requirements for
					food hygiene.
				</p>

				<RatingImage rating={"Pass"} fullSize={true} />

				<AdUnit slotId={"1221902852"} />

				<h3 className="text-bold">Improvement Required</h3>
				<p>
					This rating means the business didn't meet the legal requirements and
					is required to make improvements.
				</p>

				<RatingImage rating={"Improvement Required"} fullSize={true} />

				<AdUnit slotId={"1401850348"} />

				<h3 className="text-bold">Exempt Premises</h3>

				<p>
					This rating means the business met the legal requirements for a “Pass”
					rating but doesn't meet the criteria to be part of the scheme. These
					are businesses that wouldn't normally be classed as a food business
					and as such, are low-risk to people's health in terms of food safety.
					Examples include newsagents, chemist shops etc.
				</p>

				<RatingImage rating={"FHIS Exempt"} fullSize={true} />

				<AdUnit slotId={"8908821187"} />

				<h2 className="text-bold">The FHIS Eat Safe Award</h2>

				<p>
					The Eat Safe Award runs alongside the FHIS scheme and is awarded to
					businesses that go above and beyond the standards required to achieve
					a “Pass” rating. These businesses strive for excellence when
					delivering food hygiene standards.
				</p>

				<RatingImage rating={"Pass and Eat Safe"} fullSize={true} />

				<AdUnit slotId={"1331328463"} />
			</div>
		</div>
	);
};

export default RatingsExplainedPage;
