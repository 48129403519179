import React from "react";
import Head from "../head/head.component";
import { EstablishmentDetail } from "../types/types";

interface Props {
	establishment: EstablishmentDetail;
}

const EstablishmentHeading: React.FC<Props> = ({ establishment }) => {
	const headingName = `Food Hygiene Rating for ${establishment.businessName}`;

	return (
		<>
			<Head title={headingName} />
			<h1>{headingName}</h1>
		</>
	);
};

export default EstablishmentHeading;
