import React from "react";

import "./button.scss";

interface Props {
	text: string;
	styles?: string;
	handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button: React.FC<Props> = ({ text, styles, handleClick }) => {
	return (
		<button className={`btn ${styles}`} onClick={handleClick}>
			{text}
		</button>
	);
};

export default Button;
