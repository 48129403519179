import { InMemoryCache, NormalizedCacheObject, Reference } from '@apollo/client';

declare global {
	interface Window {
		__APOLLO_STATE__: NormalizedCacheObject;
	}
}

export const cache: InMemoryCache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				establishments: {
					keyArgs: ["input", ["businessName", "location"]],
					merge(existing, incoming) {
						let newList: Reference[] = [];

						if (existing && existing.establishments) {
							newList = newList.concat(existing.establishments);
						}
						if (incoming && incoming.establishments) {

							let incomingEstablishments: Reference[] = incoming.establishments;

							incomingEstablishments.forEach(obj => {
								const index = newList.findIndex((el) => el.__ref === obj.__ref);

								if (index >= 0) {
									newList[index] = obj;
								} else {
									newList.push(obj);
								}
							});
						}
						return {
							...incoming,
							establishments: newList,
						};
					},
				}
			},
		},
	},
}).restore(window.__APOLLO_STATE__);
