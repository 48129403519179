import React from "react";
import { EstablishmentDetail } from "../types/types";

const googleMapsApiKey: string = "AIzaSyAigryRvHzOhLJuV9xLru40ywlZGesyK50";

interface Props {
	establishment: EstablishmentDetail;
}

const EstablishmentMap: React.FC<Props> = ({ establishment }) => {
	if (!establishment.latitude || !establishment.longitude) {
		return null;
	}

	return (
		<div className="establishment-detail-section establishment-map">
			<h2>{establishment.businessName} on the map:</h2>
			<iframe
				className="mt-4"
				title={establishment.businessName}
				width="100%"
				height="600"
				frameBorder="0"
				style={{ border: 0 }}
				referrerPolicy="no-referrer-when-downgrade"
				src={encodeURI(getGoogleMapsEmbedUrl(establishment))}
				allowFullScreen
			></iframe>
		</div>
	);
};

const getGoogleMapsEmbedUrl = (establishment: EstablishmentDetail): string => {
	const latitude = establishment.latitude;
	const longitude = establishment.longitude;

	let url =
		"https://www.google.com/maps/embed/v1/place?key=" +
		googleMapsApiKey +
		"&q=" +
		latitude +
		"," +
		longitude +
		"&zoom=15";

	return url;
};

export default EstablishmentMap;
