import React from "react";
import "./feature-panel.component.scss";

const FeaturePanel: React.FC = () => {
	return (
		<section className="feature-panel">
			<h2>Latest food hygiene ratings in your area</h2>

			<p>
				We provide the latest hygiene ratings for over 500,000 of your favourite
				restaurants and takeaways (and other eateries) in your area.
			</p>
		</section>
	);
};

export default FeaturePanel;
