import React from "react";

import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { RegionData } from "../../components/types/types";
import { REGION_LIST_QUERY } from "../../gql/region.query";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import Head from "../../components/head/head.component";

const NewsRegionPage: React.FC = () => {
	const { loading, data, error } = useQuery<RegionData>(REGION_LIST_QUERY);

	if (loading) {
		return <Loading fullscreen={false} />;
	}
	if (error || !data) return <NoData />;

	const title = "Country/Regions";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					Select a region or country below to read news articles about the
					hygiene of restaurants, takeaways and other eateries in that area.
				</p>
				<div className="grid my-4">
					{data.regions
						.filter((region) => region.slug !== "scotland")
						.map((region) => (
							<Link
								key={region.id}
								to={getUrl(region.slug)}
								className="card text-center"
							>
								{region.name}
							</Link>
						))}
				</div>
			</div>
		</div>
	);
};
const getUrl = (regionSlug: string): string => {
	return `/news/${regionSlug}`;
};

export default NewsRegionPage;
