import { gql } from "@apollo/react-hooks";

export const COUNTRY_STATS_QUERY = gql`
	query CountryStats {
		countryStatsEWNI: countryStats(input: {
			orderBy: PERCENT_FIVE_RATED
			ascDesc: DESC
			schemeType: FHRS
		}) {
			countryName
			countrySlug
			totalEstablishments
			totalFiveRated
			totalFourRated
			totalFiveRatedZeroScore
			totalOneRated
			totalZeroRated
			totalPass
			totalImprovementRequired
			percentageFiveRated
			percentageOneZeroRated
		}
	}	
`;