import React from "react";
import { useQuery } from "@apollo/client";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import Head from "../../components/head/head.component";
import {
	CountryStatsData,
	CountryStatsInput,
} from "../../components/types/types";
import { COUNTRY_STATS_QUERY } from "../../gql/country-stats.query";
import { percentage } from "../../util/math";
import { Link } from "react-router-dom";
import AdUnit from "../../components/ads/ad-unit.component";

const HygieneRating5GoodPage: React.FC = () => {
	const { data, error, loading } = useQuery<
		CountryStatsData,
		CountryStatsInput
	>(COUNTRY_STATS_QUERY, {
		variables: {
			schemeType: "FHRS",
		},
		fetchPolicy: "no-cache",
		nextFetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
	});

	if (loading) return <Loading fullscreen={true} />;

	if (error || !data) return <NoData />;

	const totalEstablishmentsEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalEstablishments,
		0
	);
	const totalFiveRatedEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalFiveRated,
		0
	);

	const totalFiveRatedZeroScoreEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalFiveRatedZeroScore,
		0
	);

	const percentageFiveRatedEWNI = percentage(
		totalFiveRatedEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const percentageFiveRatedZeroScoreEWNI = percentage(
		totalFiveRatedZeroScoreEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const title = "Is A Hygiene Rating of 5 Good?";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>
				<p>
					A Food Standards Agency rating of 5 means hygiene standards are very
					good.
				</p>
				<p>
					<strong>
						A hygiene rating of 5 is at the top of the hygiene rating
						scale.&nbsp; It means that the business complies with the law.
					</strong>
				</p>

				<AdUnit slotId={"1714471845"} />

				<p>
					An establishment with a food hygiene rating of 5 will have received a
					score of between 0 and 15 points.
				</p>
				<p>
					Officers add points when there are certain shortcomings found during
					an inspection.&nbsp; The more points an establishment receives, the
					worse their hygiene rating will be.
				</p>
				<p>
					Likewise, the fewer points a business receives, the better their
					rating will be.
				</p>
				<p>
					During an inspection, a food safety officer will score a business in
					each of the following areas.
				</p>
				<p>
					<strong>
						Note: To achieve the highest rating, businesses must perform well in
						each area.
					</strong>
				</p>

				<AdUnit slotId={"1605046237"} />

				<h3>
					<strong>Hygienic food handling</strong>
				</h3>
				<p>
					Inspectors examine how well the establishment prepares and handles
					food.
				</p>
				<p>
					They also look at how the business deals with the cooking, re-heating,
					cooling and the storing of food.
				</p>
				<p>
					Inspections also cover things like temperature control and personal
					hygiene.&nbsp; In other words, how clean the chefs are working
					(washing of hands etc).
				</p>
				<p>
					Finally, inspections examine how well a business prevents
					cross-contamination.
				</p>

				<AdUnit slotId={"9401390172"} />

				<h3>
					<strong>Cleanliness and condition of facilities and building</strong>
				</h3>
				<p>
					In this area of inspection, officers examine the physical condition of
					the building.
				</p>
				<p>
					They look at how good the structure is and how well its
					maintained.&nbsp; Besides the cleanliness, the following factors are
					also considered:
				</p>
				<ul>
					<li>Building layout</li>
				</ul>
				<ul>
					<li>Building lighting</li>
				</ul>
				<ul>
					<li>Building ventilation</li>
				</ul>
				<ul>
					<li>Pest control</li>
				</ul>
				<ul>
					<li>Any other facilities</li>
				</ul>

				<AdUnit slotId={"7790330579"} />

				<h3>
					<strong>Management of food safety</strong>
				</h3>
				<p>
					This area examines the processes and procedures that are in place to
					ensure food safety.
				</p>
				<p>
					This gives the officer an idea of how likely a business is to maintain
					standards in the future.
				</p>

				<AdUnit slotId={"6654177023"} />

				<h2>
					<strong>Determining the actual hygiene rating</strong>
				</h2>
				<p>
					To determine the hygiene rating, officers add together the scores from
					each category.
				</p>
				<p>
					This final score is then used to determine the hygiene rating for the
					business.
				</p>
				<p>
					You can{" "}
					<Link to="/blog/ratings-explained">
						read more about how ratings are calculated here.
					</Link>
				</p>

				<AdUnit slotId={"3823181319"} />

				<h2>
					<strong>
						Some five-rated businesses are more hygienic than others
					</strong>
				</h2>
				<p>
					For any given area of inspection, one business may perform better than
					another.&nbsp; Yet they could still receive the same rating.
				</p>
				<p>
					For example, some businesses dont receive any points during their
					inspection.&nbsp; Not only will this get them a 5 rating, but they
					will achieve a perfect score.
				</p>
				<p>
					In this case officers were unable to find any hygiene shortcomings.
				</p>

				<AdUnit slotId={"5341095350"} />

				<p>
					Yet, some businesses may receive up to 15 points and still receive a
					rating of 5.&nbsp; In this case, hygiene standards are still deemed
					very good.&nbsp; But there is slight room for improvement.
				</p>
				<p>
					You can find out how well a business scored in each area by viewing
					the page for any given business on this website.
				</p>
				<p>
					In England, Wales and Northern Ireland, there are{" "}
					<strong>{totalEstablishmentsEWNI.toLocaleString()}</strong> food
					businesses registered with the Food Standards Agency. Out of those:
				</p>
				<ul>
					<li>
						A total of <strong>{totalFiveRatedEWNI.toLocaleString()}</strong>{" "}
						businesses have achieved a rating of 5 (
						<strong>{percentageFiveRatedEWNI}%</strong>).
					</li>

					<li>
						A total of{" "}
						<strong>{totalFiveRatedZeroScoreEWNI.toLocaleString()}</strong>{" "}
						businesses have achieved a perfect score of zero points (
						<strong>{percentageFiveRatedZeroScoreEWNI}%</strong>).
					</li>
				</ul>

				<AdUnit slotId={"2510099647"} />
			</div>
		</div>
	);
};

export default HygieneRating5GoodPage;
