import { gql } from "@apollo/react-hooks";

export const STATS_PAGE_QUERY = gql`
	query Stats {
		countryStatsEWNI: countryStats(input: {
			orderBy: PERCENT_FIVE_RATED
			ascDesc: DESC
			schemeType: FHRS
		}) {
			countryName
			countrySlug
			totalEstablishments
			totalFiveRated
			totalFiveRatedZeroScore
			totalOneRated
			totalZeroRated
			totalPass
			totalImprovementRequired
			percentageFiveRated
			percentageOneZeroRated
		}
		countryStatsScotland: countryStats(input: {
			orderBy: PERCENT_PASS_RATED
			ascDesc: DESC
			schemeType: FHIS
		}) {
			countryName
			countrySlug
			totalEstablishments
			totalPass
			totalImprovementRequired
		}
		topRegionStats: regionStats(input: {
			orderBy: PERCENT_FIVE_RATED
			ascDesc: DESC
			schemeType: FHRS
		}) {
			regionName
			regionSlug
			totalEstablishments
			totalFiveRated
			totalOneRated
			totalZeroRated
			percentageFiveRated
			percentageFiveRated
			percentageOneZeroRated
		}
		bottomRegionStats: regionStats(input: {
			orderBy: PERCENT_ONE_ZERO_RATED
			ascDesc: DESC
			schemeType: FHRS
		}) {
			regionName
			regionSlug
			totalEstablishments
			totalOneRated
			totalZeroRated
			percentageOneZeroRated
			percentageFiveRated
		}
		topAuthorityStatsEWNI: authorityStats(input: {
			orderBy: PERCENT_FIVE_RATED
			ascDesc: DESC
			schemeType: FHRS
		}) {
			localAuthorityName
			localAuthoritySlug
			totalEstablishments
			totalFiveRated
			totalOneRated
			totalZeroRated
			percentageFiveRated
			percentageFiveRated
			percentageOneZeroRated
		}
		bottomAuthorityStatsEWNI: authorityStats(input: {
			orderBy: PERCENT_ONE_ZERO_RATED
			ascDesc: DESC
			schemeType: FHRS
		}) {
			localAuthorityName
			localAuthoritySlug
			totalEstablishments
			totalFiveRated
			totalOneRated
			totalZeroRated
			percentageFiveRated
			percentageFiveRated
			percentageOneZeroRated
		}
		topAuthorityStatsScotland: authorityStats(input: {
			orderBy: PERCENT_PASS_RATED
			ascDesc: DESC
			schemeType: FHIS
		}) {
			localAuthorityName
			localAuthoritySlug
			totalEstablishments
			totalImprovementRequired
			totalPass
			percentagePassRated
			percentageImprovementRequiredRated
		}
		bottomAuthorityStatsScotland: authorityStats(input: {
			orderBy: PERCENT_IMPROVEMENT_REQUIRED_RATED
			ascDesc: DESC
			schemeType: FHIS
		}) {
			localAuthorityName
			localAuthoritySlug
			totalEstablishments
			totalImprovementRequired
			totalPass
			percentagePassRated
			percentageImprovementRequiredRated
		}
	}	
`;