import React from "react";
import { Link } from "react-router-dom";
import Head from "../../components/head/head.component";

const TermsPage: React.FC = () => {
	const title = "Terms of Use";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					Welcome to Food Owl (hereafter known as "the Website"). By using the
					Website, you agree to be bound by the following terms and conditions
					of use (the "Terms") and our{" "}
					<Link to={"/privacy-policy"}>Privacy Policy.</Link> If you do not
					agree to these Terms and our Privacy Policy, please do not use the
					Website.
				</p>

				<h2>Use of the Website</h2>
				<p>
					The Website is intended for your personal use and for non-commercial
					purposes. By accessing and using the Website, you agree to use it in
					accordance with these terms and conditions. You are prohibited from
					using the Website for any illegal or unauthorized purpose, including
					but not limited to:
				</p>
				<ul>
					<li>
						Tampering, hacking, modifying or otherwise corrupting the security
						of the Website
					</li>
					<li>
						Transmitting any viruses, worms, defects, Trojan horses, or any
						items of a destructive nature
					</li>
					<li>
						Attempting to gain unauthorised access to the Website or its related
						systems or networks
					</li>
					<li>
						Using the Website to transmit or post any material that is
						defamatory, offensive, or otherwise in breach of any applicable laws
						or regulations
					</li>
					<li>
						Using the Website to promote or engage in any fraudulent, illegal or
						unethical activity
					</li>
					<li>
						Violation of any of these provisions may result in termination of
						your access to the Website and possible legal action.
					</li>
				</ul>
				<h2>Intellectual Property Rights and Copyright</h2>
				<p>
					All content on the Website, including but not limited to text,
					graphics, logos, images, and software, is the property of Food Owl or
					its licensors and is protected by international copyright laws.&nbsp;
				</p>
				<p>
					This content is provided to you as a service, and is intended for your
					personal and non-commercial use only. You may not use any content on
					the Website for commercial purposes without obtaining a license to do
					so from us or our licensors.
				</p>
				<p>
					You may not reproduce, duplicate, copy, sell, resell or exploit any
					portion of the Website, use of the Website, or access to the Website
					without the express written permission by the Food Owl Website. Any
					unauthorised use terminates the permission or license granted by Food
					Owl.
				</p>
				<p>
					You are also prohibited from modifying, distributing, transmitting,
					displaying, publishing, selling, licensing, creating derivative works
					or using any content available on or through the Website for
					commercial or public purposes without our permission.
				</p>
				<p>
					The Website and its content, features and functionality are protected
					by copyright laws. You may not use any content on the Website for
					commercial purposes without obtaining a license to do so from us or
					our licensors.
				</p>
				<p>
					If you have any questions about our Intellectual Property Rights,
					please contact us.
				</p>
				<h2>Disclaimer</h2>
				<p>
					The Food Owl Website provides food hygiene rating data on businesses
					and individuals. This data is obtained from the UK Food Standards
					Agency and is displayed on our Website as is.&nbsp; We take great care
					to ensure that the information provided is accurate and up-to-date,
					however, we cannot guarantee the completeness or accuracy of the
					information provided by the UK Food Standards Agency.
				</p>
				<p>
					We will not be held liable for any inaccuracies or any consequences of
					displaying the names of businesses or individuals on our Website as a
					result of using the data provided by the UK Food Standards Agency.
				</p>
				<p>
					If you are a business or individual whose name appears on our Website
					and you have concerns about the accuracy or privacy of the
					information, please contact us and we will review and update the
					information as necessary.
				</p>
				<p>
					By using this Website, you agree to use the data provided on the
					Website for informational purposes only and is not intended to be
					relied upon for any specific purpose and should not be used as a
					substitute for professional advice.
				</p>
				<h2>Limitation of Liability</h2>
				<p>
					The Food Owl Website shall not be liable for any direct, indirect,
					incidental, special, or consequential damages, including but not
					limited to, damages for loss of profits, goodwill, use, data, or other
					intangible losses, arising out of or in connection with the use of the
					Website or the inability to use the Website, or the contents, products
					or services provided on or through the Website, whether based on
					warranty, contract, tort, negligence, strict liability or otherwise,
					even if the Food Owl Website has been advised of the possibility of
					such damages.
				</p>
				<p>
					In no event shall Food Owl’s Website's total liability to you for all
					damages, losses, and causes of action arising out of or relating to
					your use of the Website, or the contents, products or services
					provided on or through the Website, whether in contract, tort,
					negligence, strict liability or otherwise, exceed the amount paid by
					you, if any, for accessing the Website.
				</p>
				<p>
					This limitation of liability applies to all claims, whether based on
					warranty, contract, tort, or any other legal theory, whether or not
					the Food Owl Website has been informed of the possibility of such
					damage, and further where a remedy set forth in this agreement is
					found to have failed of its essential purpose.
				</p>
				<p>
					Please note that some jurisdictions may not allow the limitation or
					exclusion of liability for incidental or consequential damages, so
					some of the above limitations may not apply to you.
				</p>
				<h2>Indemnification</h2>
				<p>
					You agree to indemnify and hold Food Owl, its affiliates, directors,
					officers, employees, and agents, harmless from and against any claims,
					actions or demands, liabilities, and settlements, including without
					limitation, reasonable legal and accounting fees, resulting from, or
					alleged to result from, your use of the Website in a manner that
					violates or is alleged to violate these Terms and Conditions.
				</p>
				<h2>Changes to the Website and Terms</h2>
				<p>
					The Food Owl Website reserves the right to make changes to the Website
					and these Terms at any time without notice. We may update or modify
					these Terms from time to time and will post the updated version on the
					Website.&nbsp;
				</p>
				<p>
					By continuing to access and use the Website after any changes have
					been made, you are indicating your acceptance of the new Terms.
				</p>
				<p>
					It is your responsibility to review these Terms regularly. If you do
					not agree with any changes made to the Terms, you must discontinue
					your use of the Website.
				</p>
				<p>
					The Food Owl Website also reserves the right to make changes, updates
					or modifications to the Website, its features or functionality, or the
					information, materials, products or services provided on or through
					the Website at any time without notice.
				</p>
				<p>
					Please check these terms and conditions regularly to ensure you are
					aware of any changes. If you do not agree with any of the changes made
					to the Terms, you must discontinue your use of the Website.
				</p>
				<p>
					If you have any questions or concerns about these changes, please
					contact us.
				</p>
				<p>
					By accessing and using this website you agree to be bound by these
					terms and conditions.
				</p>
			</div>
		</div>
	);
};

export default TermsPage;
