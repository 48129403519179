import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
	EstablishmentNameLocationInput,
	EstablishmentsSummaryData,
	SearchResult,
} from "../../components/types/types";
import { GET_ESTABLISHMENTS_QUERY } from "../../gql/establishments.query";

import { SearchResultState } from "../../components/types/types";

import "./search-results.page.scss";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import SearchResults from "../../components/search/search-results.component";

const SearchResultsPage: React.FC = () => {
	const location = useLocation<SearchResultState>();

	const searchParams = new URLSearchParams(location.search);

	const searchResult: SearchResult = {
		name: searchParams.get("name") || "",
		location: searchParams.get("location") || "",
	};

	const { loading, data, error, fetchMore } = useQuery<
		EstablishmentsSummaryData,
		EstablishmentNameLocationInput
	>(GET_ESTABLISHMENTS_QUERY, {
		variables: {
			businessName: searchResult.name,
			location: searchResult.location,
			limit: 10,
		},
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-first",
		notifyOnNetworkStatusChange: true,
	});

	const [isLoadingMore, setIsLoadingMore] = useState(false);

	if (loading) return <Loading fullscreen={true} />;
	if (error || !data || data?.establishments.size === 0) return <NoData />;

	const handleLoadMoreButtonClick = async () => {
		setIsLoadingMore(true);
		await fetchMore({
			variables: {
				businessName: searchResult.name,
				location: searchResult.location,
				limit: 10,
				cursor: data.establishments.nextCursor,
			},
		});
		setIsLoadingMore(false);
	};

	return (
		<div className="container">
			<div className="article">
				<SearchResults
					heading={"Search results..."}
					isLoadingMore={isLoadingMore}
					establishments={data.establishments.establishments}
					nextCursor={data.establishments.nextCursor}
					handleClick={handleLoadMoreButtonClick}
				/>
			</div>
		</div>
	);
};

export default SearchResultsPage;
