import React from "react";
import { Link } from "react-router-dom";
import { EstablishmentDetail } from "../types/types";

interface Props {
	establishment: EstablishmentDetail;
}

const EstablishmentRating: React.FC<Props> = ({ establishment }) => {
	const ratingValue = establishment.ratingValue;

	const hasStarRating: boolean =
		ratingValue !== "AwaitingInspection" &&
		ratingValue !== "AwaitingPublication" &&
		ratingValue !== "Exempt";

	const hasNewRatingPending = establishment.newRatingPending;

	let humanReadableRatingValue: string = ratingValue;

	if (ratingValue === "AwaitingInspection") {
		humanReadableRatingValue = "Awaiting Inspection";
	} else if (ratingValue === "AwaitingPublication") {
		humanReadableRatingValue = "Awaiting Publication";
	}

	if (hasNewRatingPending) {
		return (
			<div className="rating-details__text">
				** Recently inspected – new rating to be published soon **
			</div>
		);
	} else if (hasStarRating) {
		return (
			<>
				<div className="rating-details__text">
					{establishment.businessName} was awarded a
					<span className="text-bold">
						&nbsp;food hygiene Rating of {humanReadableRatingValue}
					</span>
					&nbsp;by&nbsp;
					<a
						href={establishment.authority.url}
						target="_blank"
						rel="noopener noreferrer"
					>
						{`${establishment.authority.name} Council`}
					</a>
					&nbsp;on {establishment.ratingDate}.
				</div>
				<div className="rating-details__text">
					For more information on what a food hygiene rating of{" "}
					{humanReadableRatingValue} means,{" "}
					<Link to={getArticleLink(ratingValue)}>read this article.</Link>
				</div>
			</>
		);
	} else {
		return (
			<div className="rating-details__text">
				{establishment.businessName} is currently
				<span className="text-bold">&nbsp;{humanReadableRatingValue}</span>
				&nbsp;by&nbsp;
				<a
					href={establishment.authority.url}
					target="_blank"
					rel="noopener noreferrer"
				>
					{`${establishment.authority.name} Council`}
				</a>
			</div>
		);
	}
};

const getArticleLink = (rating: string) => {
	switch (rating) {
		case "5": {
			return "/blog/is-hygiene-rating-5-good";
		}
		case "4": {
			return "/blog/is-hygiene-rating-4-good";
		}
		case "3": {
			return "/blog/is-hygiene-rating-3-good";
		}
		case "2": {
			return "/blog/is-hygiene-rating-2-good";
		}
		case "1": {
			return "/blog/1-star-hygiene-rating-meaning";
		}
		case "0": {
			return "/blog/zero-food-hygiene-ratings-guide";
		}
	}

	return "/blog/ratings-explained";
};

export default EstablishmentRating;
