import React from "react";
import { useQuery } from "@apollo/client";
import Loading from "../../components/loading/loading-component";
import NoData from "../../components/no-data/no-data.component";
import Head from "../../components/head/head.component";
import {
	CountryStatsData,
	CountryStatsInput,
} from "../../components/types/types";
import { COUNTRY_STATS_QUERY } from "../../gql/country-stats.query";
import { percentage } from "../../util/math";
import { Link } from "react-router-dom";
import AdUnit from "../../components/ads/ad-unit.component";

const HygieneRating4GoodPage: React.FC = () => {
	const { data, error, loading } = useQuery<
		CountryStatsData,
		CountryStatsInput
	>(COUNTRY_STATS_QUERY, {
		variables: {
			schemeType: "FHRS",
		},
		fetchPolicy: "no-cache",
		nextFetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
	});

	if (loading) return <Loading fullscreen={true} />;

	if (error || !data) return <NoData />;

	const totalEstablishmentsEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalEstablishments,
		0
	);
	const totalFourRatedEWNI = data.countryStatsEWNI.reduce(
		(acc, country) => acc + country.totalFourRated,
		0
	);
	const percentageFourRatedEWNI = percentage(
		totalFourRatedEWNI,
		totalEstablishmentsEWNI
	).toFixed(2);

	const title = "Is A Hygiene Rating of 4 Good?";

	return (
		<div className="container panel">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>
					A Food Standards Agency rating of 4 means hygiene standards are good.
				</p>
				<p>
					<strong>
						A hygiene rating of 4 is near the top of the hygiene rating
						scale.&nbsp; It means that the business complies with the law.
					</strong>
				</p>

				<AdUnit slotId={"1906043531"} />

				<p>
					An establishment with a food hygiene rating of 4 will have received a
					score of 20 points.
				</p>
				<p>
					Officers add points when there are certain shortcomings found during
					an inspection.&nbsp; The more points an establishment receives, the
					worse their hygiene rating will be.
				</p>
				<p>
					Likewise, the fewer points a business receives, the better their
					rating will be.
				</p>
				<p>
					During an inspection, a food safety officer will score a business in
					each of the following areas.
				</p>
				<p>
					<strong>
						Note: To achieve the highest rating, businesses must perform well in
						each area.
					</strong>
				</p>

				<AdUnit slotId={"2838236855"} />

				<h3>
					<strong>Hygienic food handling</strong>
				</h3>
				<p>
					Inspectors examine how well the establishment prepares and handles
					food.
				</p>
				<p>
					They also look at how the business deals with cooking, re-heating,
					cooling and storing of food.
				</p>
				<p>
					Inspections also cover things like temperature control and personal
					hygiene.&nbsp; In other words, how clean the chefs are working
					(washing of hands etc).
				</p>
				<p>
					Finally, inspections examine how well a business prevents
					cross-contamination.
				</p>

				<AdUnit slotId={"7898991849"} />

				<h3>
					<strong>Cleanliness and condition of facilities and building</strong>
				</h3>
				<p>
					In this area of inspection, officers examine the physical condition of
					the building.
				</p>
				<p>
					They look at how good the structure is and how well its
					maintained.&nbsp; Besides the cleanliness, the following factors are
					also considered:
				</p>
				<ul>
					<li>Building layout</li>
				</ul>
				<ul>
					<li>Building lighting</li>
				</ul>
				<ul>
					<li>Building ventilation</li>
				</ul>
				<ul>
					<li>Pest control</li>
				</ul>
				<ul>
					<li>Any other facilities</li>
				</ul>

				<AdUnit slotId={"5272828509"} />

				<h3>
					<strong>Management of food safety</strong>
				</h3>
				<p>
					This area examines the processes and procedures that are in place to
					ensure food safety.
				</p>
				<p>
					This gives the officer an idea of how likely a business is to maintain
					standards in the future.
				</p>
				<h2>
					<strong>Determining the actual hygiene rating</strong>
				</h2>
				<p>
					To determine the hygiene rating, officers add together the scores from
					each category.
				</p>
				<p>
					This final score is then used to determine the hygiene rating for the
					business.
				</p>
				<p>
					You can{" "}
					<Link to="/blog/ratings-explained">
						read more about how ratings are calculated here.
					</Link>
				</p>

				<AdUnit slotId={"1333583494"} />

				<h3>
					<strong>Some 4 rated businesses are more hygienic than others</strong>
				</h3>
				<p>
					For any given area of inspection, one business may perform better than
					another.&nbsp; Yet they could still receive the same rating.
				</p>
				<p>
					So for example, one business might score a Very good for Hygienic food
					handling.&nbsp; But then receive a score of Generally satisfactory and
					Good for the other two areas.
				</p>
				<p>
					Another business may score ​​Good for the first two categories.&nbsp;
					But then receive Generally satisfactory for the Management of food
					safety.
				</p>

				<AdUnit slotId={"9592961869"} />

				<p>
					You can find out how well a business scored in each area by viewing
					the page for any given business on this website.
				</p>
				<p>
					In England, Wales and Northern Ireland, there are{" "}
					<strong>{totalEstablishmentsEWNI.toLocaleString()}</strong> food
					businesses registered with the Food Standards Agency. Out of those:
				</p>

				<ul>
					<li>
						A total of <strong>{totalFourRatedEWNI.toLocaleString()}</strong>{" "}
						businesses have achieved a rating of 4 (
						<strong>{percentageFourRatedEWNI}%</strong>).
					</li>
				</ul>

				<AdUnit slotId={"6394338483"} />
			</div>
		</div>
	);
};

export default HygieneRating4GoodPage;
