import React from "react";

import ArticleCard from "../../components/article-card/article-card.component";
import Head from "../../components/head/head.component";

const BlogPage: React.FC = () => {
	const title = "Latest Blog Posts";

	return (
		<div className="container">
			<div className="article">
				<Head title={title} />
				<h1 className="text-bold">{title}</h1>

				<p>Here's the latest blog posts from the Food Owl blog.</p>

				<ArticleCard
					headline={
						"Rating the Hygiene Standards of UK Food Businesses: The Latest Statistics"
					}
					caption={
						"In this blog post, we uncover the areas in the UK that are leading the way in food safety and which ones may be falling behind."
					}
					articlePath={"/blog/food-hygiene-rating-statistics"}
				/>

				<ArticleCard
					headline={"Ratings Explained"}
					caption={
						"In this blog post, we look at the different Food Standards Agency ratings, how they're calculated and more."
					}
					articlePath={"/blog/ratings-explained"}
				/>

				<ArticleCard
					headline={"Zero Food Hygiene Ratings: Everything You Need To Know"}
					caption={
						"This article is a complete overview of zero food hygiene ratings. It covers the meaning of a zero rating, the repercussions of receiving one and practical advice on how to avoid it and maintain a high rating for your food establishment. "
					}
					articlePath={"/blog/zero-food-hygiene-ratings-guide"}
				/>
				<ArticleCard
					headline={
						"Boost Your Business: How to Earn a 5-Star Food Hygiene Rating"
					}
					caption={
						"In this blog post, we take a detailed look at what you need to do to get a 5-star food hygiene rating."
					}
					articlePath={"/blog/how-to-five-star-rating"}
				/>
				<ArticleCard
					headline={
						"Boost Your Hygiene Rating With These Proven Food Hygiene Tips"
					}
					caption={
						"Here's some top food hygiene tips from real people who have undergone food hygiene inspections in the UK"
					}
					articlePath={"/blog/food-hygiene-tips"}
				/>

				<ArticleCard
					headline={"Food Hygiene Rating FAQs"}
					caption={
						"In this blog post, we look at some common questions people have around Food Hygiene Ratings."
					}
					articlePath={"/blog/food-hygiene-rating-faqs"}
				/>

				<ArticleCard
					headline={"Is A Hygiene Rating Of 5 Good?"}
					caption={
						"Uncover the truth behind a hygiene rating of 5 and find out if it's truly considered good in this informative article."
					}
					articlePath={"/blog/is-hygiene-rating-5-good"}
				/>

				<ArticleCard
					headline={"Is A Hygiene Rating Of 4 Good?"}
					caption={
						"Learn about the meaning of a hygiene rating of 4 and if it's considered good in this informative article."
					}
					articlePath={"/blog/is-hygiene-rating-4-good"}
				/>

				<ArticleCard
					headline={"Is A Hygiene Rating Of 3 Good?"}
					caption={
						"In this informative article, find out what a hygiene rating of 3 entails and if it's considered good."
					}
					articlePath={"/blog/is-hygiene-rating-3-good"}
				/>

				<ArticleCard
					headline={"Is A Hygiene Rating Of 2 Good?"}
					caption={
						"In this blog post, we look at what a hygiene rating of 2 really means."
					}
					articlePath={"/blog/is-hygiene-rating-2-good"}
				/>

				<ArticleCard
					headline={"What Does A 1 Star Hygiene Rating Mean?"}
					caption={
						"In this blog post, we look at what a hygiene rating of 1 really means."
					}
					articlePath={"/blog/1-star-hygiene-rating-meaning"}
				/>
			</div>
		</div>
	);
};

export default BlogPage;
