import { Route, Switch, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Head from "./components/head/head.component";
import AboutPage from "./pages/about/about.page";
import AdvertisePage from "./pages/advertise/advertise.page";
import ArticlesAuthorityPage from "./pages/authority/articles-authority.page";
import AuthorityBusinessesPage from "./pages/authority/authority-businessess.page";
import AuthorityPage from "./pages/authority/authority.page";
import BlogPage from "./pages/blog/blog.page";
import BusinessTypesPage from "./pages/business-types/business-types.page";
import EstablishmentDetailPage from "./pages/establishment-detail/establishment-detail.page";
import HomePage from "./pages/home/home.page";
import CleanestTakeawaysSandwichShopsPage from "./pages/news/cleanest-takeaways-sandwich-shops.page";
import NewsListPage from "./pages/news/news-list.page";
import NewsRegionPage from "./pages/news/news-region.page";
import ZeroRatedPage from "./pages/news/zero-rated.post";
import PrivacyPage from "./pages/privacy/privacy.page";
import RatingsExplainedPage from "./pages/blog/ratings-explained.page";
import SearchResultsPage from "./pages/search-results/search-results.page";
import HygieneRating5GoodPage from "./pages/blog/hygiene-rating-5-good.page";
import HygieneRating4GoodPage from "./pages/blog/hygiene-rating-4-good.page";
import HygieneRating3GoodPage from "./pages/blog/hygiene-rating-3-good.page";
import HygieneRating2GoodPage from "./pages/blog/hygiene-rating-2-good.page";
import HygieneRating1MeaningPage from "./pages/blog/hygiene-rating-1-meaning.page";
import FoodHygieneRatingsFaqsPage from "./pages/blog/food-hygiene-ratings-faqs.page";
import ResourcesPage from "./pages/resources/resources.page";
import HowTo5StarRatingPage from "./pages/blog/how-to-5-star-rating.page";
import FoodHygieneTipsPage from "./pages/blog/food-hygiene-tips.page";
import ZeroFoodHygieneRatingsPage from "./pages/blog/zero-food-hygiene-ratings.page";
import FoodHygieneRatingsStatsPage from "./pages/blog/food-hygiene-rating-stats.page";
import TermsPage from "./pages/terms/terms.page";

export const Main: React.FC = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return (
		<>
			<Head
				title={
					"Food Owl: Food Hygiene Ratings For Takeaways, Restaurants and other eateries across the UK."
				}
			/>
			<Switch>
				<Route exact path="/" component={HomePage} />
				<Route exact path="/resources" component={ResourcesPage} />
				<Route exact path="/advertise" component={AdvertisePage} />
				<Route exact path="/about" component={AboutPage} />
				<Route exact path="/privacy-policy" component={PrivacyPage} />
				<Route exact path="/terms-of-use" component={TermsPage} />
				<Route exact path="/search-results" component={SearchResultsPage} />
				<Route
					exact
					path="/establishments/:regionSlug"
					component={AuthorityPage}
				/>
				<Route
					exact
					path="/establishments/:regionSlug/:authoritySlug"
					component={BusinessTypesPage}
				/>
				<Route
					exact
					path="/establishments/:regionSlug/:authoritySlug/:businessTypeSlug"
					component={AuthorityBusinessesPage}
				/>
				{/* Blog Articles */}
				<Route exact path="/blog/" component={BlogPage} />
				<Route
					exact
					path="/blog/ratings-explained"
					component={RatingsExplainedPage}
				/>
				<Route
					exact
					path="/blog/is-hygiene-rating-5-good"
					component={HygieneRating5GoodPage}
				/>
				<Route
					exact
					path="/blog/is-hygiene-rating-4-good"
					component={HygieneRating4GoodPage}
				/>
				<Route
					exact
					path="/blog/is-hygiene-rating-3-good"
					component={HygieneRating3GoodPage}
				/>
				<Route
					exact
					path="/blog/is-hygiene-rating-2-good"
					component={HygieneRating2GoodPage}
				/>
				<Route
					exact
					path="/blog/1-star-hygiene-rating-meaning"
					component={HygieneRating1MeaningPage}
				/>
				<Route
					exact
					path="/blog/food-hygiene-rating-faqs"
					component={FoodHygieneRatingsFaqsPage}
				/>
				<Route
					exact
					path="/blog/how-to-five-star-rating"
					component={HowTo5StarRatingPage}
				/>
				<Route
					exact
					path="/blog/food-hygiene-tips"
					component={FoodHygieneTipsPage}
				/>
				<Route
					exact
					path="/blog/zero-food-hygiene-ratings-guide"
					component={ZeroFoodHygieneRatingsPage}
				/>

				<Route
					exact
					path="/blog/food-hygiene-rating-statistics"
					component={FoodHygieneRatingsStatsPage}
				/>

				{/* News Articles */}
				<Route exact path="/news/" component={NewsRegionPage} />
				<Route
					exact
					path="/news/:regionSlug"
					component={ArticlesAuthorityPage}
				/>
				<Route
					exact
					path="/news/:regionSlug/:authoritySlug"
					component={NewsListPage}
				/>
				<Route
					path="/news/:regionSlug/:authoritySlug/cleanest-takeaways-sandwich-shops"
					component={CleanestTakeawaysSandwichShopsPage}
				/>
				<Route
					path="/news/:regionSlug/:authoritySlug/zero-food-hygiene-rating"
					component={ZeroRatedPage}
				/>
				{/* Establishment Detail Pages */}
				<Route path="/:businessName/:id" component={EstablishmentDetailPage} />
			</Switch>
		</>
	);
};
