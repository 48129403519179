import React from "react";

import { useQuery } from "@apollo/client";
import { REGION_LIST_QUERY as REGIONS_QUERY } from "../../gql/region.query";
import { RegionData } from "../types/types";
import { Link } from "react-router-dom";
import Loading from "../loading/loading-component";
import NoData from "../no-data/no-data.component";

const RegionPanel: React.FC = () => {
	const { loading, data, error } = useQuery<RegionData>(REGIONS_QUERY);

	if (loading) {
		return <Loading fullscreen={false} />;
	}
	if (error || !data) return <NoData />;

	return (
		<section className="panel my-4">
			<div className="container">
				<div className="grid">
					{data.regions.map((region) => (
						<Link
							key={region.id}
							to={getUrl(region.slug)}
							className="card text-center"
						>
							{region.name}
						</Link>
					))}
				</div>
			</div>
		</section>
	);
};
const getUrl = (regionSlug: string): string => {
	return `/establishments/${regionSlug}`;
};
export default RegionPanel;
