import React from "react";
import { useAnalytics } from "../../util/analytics";
import Address from "../address/address.component";
import LocalAuthorityDetails from "../authority/local-authority-details-component";
import BusinessOwners from "../business-owners/business-owners.component";
import AdUnit from "../ads/ad-unit.component";
import InspectionDate from "../inspection/inspection-date.component";
import LastUpdated from "../last-updated/last-updated.component";
import EstablishmentMap from "../map/establishment-map";
import RatingDetail from "../rating/rating-detail.component";
import RatingScores from "../rating/rating-scores.component";
import SourcesLinks from "../sources-links/sources-links.component";
import { EstablishmentDetail } from "../types/types";
import "./establishment-detail.component.scss";
import EstablishmentHeading from "./establishment-heading.component";

interface Props {
	establishment: EstablishmentDetail;
}

const EstablishmentDetailComponent: React.FC<Props> = ({ establishment }) => {
	const { trackEvent } = useAnalytics();

	trackEvent("local_authority_pageview", {
		local_authority: establishment.authority.name,
	});

	trackEvent("region_pageview", {
		region: establishment.authority.region.name,
	});

	return (
		<div className="container">
			<div className="establishment-detail-section ">
				<EstablishmentHeading establishment={establishment} />

				<div className="establishment-detail-section__info">
					<div className="establishment-detail-section__info__rating">
						<RatingDetail establishment={establishment} />
					</div>
					<div className="establishment-detail-section__info__misc">
						<Address establishment={establishment} />
						<InspectionDate establishment={establishment} />
						<LastUpdated establishment={establishment} />
						<SourcesLinks establishment={establishment} />
					</div>
				</div>
			</div>

			<AdUnit slotId={"4706067894"} />

			<EstablishmentMap establishment={establishment} />

			<AdUnit slotId={"6947435468"} />

			<RatingScores establishment={establishment} />

			<AdUnit slotId={"9575251194"} />

			<LocalAuthorityDetails establishment={establishment} />

			<AdUnit slotId={"8262169527"} />

			<BusinessOwners />

			<AdUnit slotId={"6949087855"} />
		</div>
	);
};

export default EstablishmentDetailComponent;
