import React from "react";
import { EstablishmentDetail } from "../types/types";
import "./sources-links.component.scss";

interface Props {
	establishment: EstablishmentDetail;
}

const SourcesLinks: React.FC<Props> = ({ establishment }) => {
	return (
		<div className="sources">
			<div className="sources__heading">Source:</div>

			<div className="sources__line1">
				<a
					href={
						"https://ratings.food.gov.uk/business/en-GB/" + establishment.fhrsId
					}
					target="_blank"
					rel="noopener noreferrer"
				>
					{"Food Standards Agency"}
				</a>
			</div>
		</div>
	);
};

export default SourcesLinks;
