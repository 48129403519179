import React from "react";

import { AuthorityData } from "../types/types";
import { Link } from "react-router-dom";

interface Props {
	authorityData: AuthorityData;
}

const AuthorityComponent: React.FC<Props> = ({ authorityData }) => {
	return (
		<div className="grid my-4">
			{authorityData?.authorityList?.authorities?.map((authority) => (
				<Link
					key={authority.id}
					to={getUrl(authority.region.slug, authority.slug)}
					className="article-card text-center"
				>
					{authority.name}
				</Link>
			))}
		</div>
	);

	function getUrl(regionSlug: string, authoritySlug: string): string {
		return `${regionSlug}/${authoritySlug}`;
	}
};

export default AuthorityComponent;
