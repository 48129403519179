import React from "react";
import { EstablishmentDetail } from "../types/types";

import "./local-authority-details.component.scss";

interface Props {
	establishment: EstablishmentDetail;
}

const LocalAuthorityDetails: React.FC<Props> = ({ establishment }) => {
	const businessType = (
		<div className="establishment-detail-section local-authority">
			<div className="local-authority__details">
				<h2>Local Authority:</h2>
				<div className="local-authority-details__line1">
					{`Name: ${establishment.authority.name}`}
				</div>
				<div className="local-authority-details__line2">
					Email:{" "}
					<a
						href={"mailto:" + establishment.authority.email}
						target="_blank"
						rel="noopener noreferrer"
					>
						{establishment.authority.email}
					</a>
				</div>
				<div className="local-authority-details__line3">
					Website:{" "}
					<a
						href={establishment.authority.url}
						target="_blank"
						rel="noopener noreferrer"
					>
						{establishment.authority.url}
					</a>
				</div>
			</div>
		</div>
	);

	return businessType;
};

export default LocalAuthorityDetails;
