import React from "react";

interface Props {
	value: string;
	styles?: string;
}

const SubmitButton: React.FC<Props> = ({ value, styles }) => {
	return (
		<div className="form-control">
			<input type="submit" value={value} className={`btn ${styles}`}></input>
		</div>
	);
};

export default SubmitButton;
