import React from "react";
import { EstablishmentDetail } from "../types/types";
import "./address.component.scss";

interface Props {
	establishment: EstablishmentDetail;
}

const Address: React.FC<Props> = ({ establishment }) => {
	const address = (
		<div className="address">
			<div className="address__heading">Address:</div>
			<div className="address__line1">{establishment.addressLine1}</div>
			<div className="address__line2">{establishment.addressLine2}</div>
			<div className="address__line3">{establishment.addressLine3}</div>
			<div className="address__line4">{establishment.addressLine4}</div>
			<div className="address__postcode">{establishment.postcode}</div>
		</div>
	);

	return establishment.addressLine1 ? address : null;
};

export default Address;
